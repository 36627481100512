<div class="container">
  <div class="title">Rate & Reward</div>
  <ng-container *ngIf="voteObject.type=== 'company'">
    <div class="subtitle" style="white-space: pre-wrap">{{ 'rate_reward_msg_hotel' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="voteObject.type=== 'employee'">
    <div class="subtitle">{{ 'rate_reward_msg_employee' | translate }}</div>
  </ng-container>
  <ng-container *ngIf="voteObject.type=== 'department'">
    <div class="subtitle">{{ 'rate_reward_msg_department' | translate }}</div>
  </ng-container>
  <div class="content">
    <form [formGroup]="voteForm">
      <ng-container *ngIf="voteObject.type=== 'company'">
        <div class="row">
          <div class="label col-5">{{ 'hotel' | translate }}</div>
          <div class="input col-7">Cavallino Bianco</div>
        </div>
      </ng-container>
      <ng-container *ngIf="voteObject.type !== 'company'">
        <div class="row">
          <div class="label col-5">{{ 'department' | translate }}</div>
          <div class="input col-7">{{ departmentName }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="voteObject.type === 'employee'">
        <div class="row">
          <div class="label col-5">{{ 'employee' | translate }}</div>
          <div class="input col-7">{{employeeName | titlecase}}<br><br>
            <img *ngIf="employeePicturePath; else noImage" src="{{employeePicturePath}}" alt="{{employeePictureName}}" title="{{employeePictureName}}" style="border-radius: 5px;" />
            <ng-template #noImage>
              <img [width]="80" height="80px" src="assets/media/images/user_placeholder.png" title="no-picture" style="border-radius: 5px">
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="ratingIsAllowed;else templateNotAllowed">
        <div class="row">
          <div class="label col-5">{{ 'rate_here' | translate }}</div>
          <div class="input col-7">
            <span *ngFor="let item of stars" [ngClass]="ratingIcon(item)" (click)="onRatingClick(item)"></span>
          </div>
        </div>
        <div class="row">
          <div class="label col-5">{{ 'comment' | translate }}</div>
          <div class="input col-7">
            <kendo-formfield>
              <kendo-textarea
                #comment
                [style.width.perc]="100"
                formControlName="comment"
                [rows]="5"
                flow="horizontal"
                placeholder="{{ 'comment_placeholder' | translate }}"
                resizable="none"
                [maxlength]="commentMaxLength"
                (valueChange)="onCommentValueChange($event)"
              >
              </kendo-textarea>
              <div>
                <kendo-textarea-suffix class="custom-styling">
                  <span class="counter">{{ commentCounter }}</span>
                  <button
                    kendoButton
                    class="suffix-clear-button"
                    fillMode="clear"
                    [svgIcon]="xSvg"
                    (click)="clearValue()"
                  ></button>
                </kendo-textarea-suffix>
              </div>
            </kendo-formfield>
          </div>
        </div>
        <ng-container *ngIf="voteObject.voteStars >= minimumStarsToVote">
          <ng-container *ngIf="voteObject.type=== 'company'">
            <div class="pre-tip">{{ 'pre_tip_msg_hotel' | translate }}</div>
          </ng-container>
          <ng-container *ngIf="voteObject.type=== 'employee'">
            <div class="pre-tip">{{ 'pre_tip_msg_employee' | translate }}</div>
          </ng-container>
          <ng-container *ngIf="voteObject.type=== 'department'">
            <div class="pre-tip">{{ 'pre_tip_msg_department' | translate }}</div>
          </ng-container>
          <div class="row">
            <div class="label col-5">{{ 'reward_amount' | translate }}</div>
            <div class="input col-7">
              <kendo-formfield>
                <kendo-numerictextbox
                  #tipAmount
                  format="c0"
                  [min]="0"
                  formControlName="tipAmount"
                  [format]="formatOptions"
                  >
                </kendo-numerictextbox>
              </kendo-formfield>
            </div>
          </div>
          <div class="row">
            <div class="label col-5">Email</div>
            <div class="input col-7">
              <kendo-formfield>
                <input
                  #emailPaymentReceipt
                  class="k-input k-input-solid k-rounded-md"
                  type="email"
                  name="email"
                  autocomplete="on"
                  maxlength="100"
                  formControlName="emailPaymentReceipt"
                  [required]="(this.voteForm.controls['tipAmount'].value > 0)"
                />
                <kendo-formerror>{{ 'invalid_mail_msg' | translate }}</kendo-formerror>
              </kendo-formfield>
            </div>
            <div class="info col-12">({{ 'receipt_mail_msg' | translate }})</div>
          </div>
        </ng-container>
        <div class="button-row">
          <button *ngIf="!showLoading" kendoButton class="button-primary" (click)="doAction('vote')">
            {{ 'proceed' | translate }}
          </button>
          <kendo-loader *ngIf="showLoading"></kendo-loader>
        </div>
        <div style="color: red; text-align: center;" *ngIf="errorMsg.length > 0">{{ errorMsg }}</div>
      </ng-container>
      <ng-template #templateNotAllowed>
        <div class="messageNotAllowed">
          {{ 'rate_again_error' | translate: { employeeName: (employeeName | titlecase) } }}
        </div>
      </ng-template>
    </form>
  </div>
</div>
<!--dialog star not choosen-->
<kendo-dialog
  class="dialog-rt dialog-star-not-choosen "
  *ngIf="dialogStarNotFoundActive"
  (close)="closeDialogStarNotFound()"
  [minWidth]="300"
  [width]="300"
>
  <kendo-dialog-titlebar>{{ 'rate_not_found' | translate }}</kendo-dialog-titlebar>
  <p style="margin: 30px; text-align: center;">
    {{ 'rate_not_found_msg' | translate }}
  </p>
  <kendo-dialog-actions>
    <button kendoButton class="close-button" themeColor="primary" (click)="closeDialogStarNotFound()" >
      {{ 'try_again' | translate }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
<!--dialog thanks without tip-->
<kendo-dialog
  class="dialog-rt dialog-star-not-choosen"
  *ngIf="dialogProcedeWithoutPaymentActive"
  (close)="doAction('closeDialogThanksWithoutPayment')"
  [minWidth]="300"
  [width]="300"
  close
>
  <kendo-dialog-titlebar>{{ 'thanks_rate' | translate }}</kendo-dialog-titlebar>
  <p style="margin: 30px; text-align: center;">
    {{ 'thanks_rate_another' | translate }}
  </p>
  <kendo-dialog-actions>
    <button kendoButton class="confirm-button" themeColor="primary" (click)="doAction('newVote')" >
      {{ 'rate_again' | translate | uppercase }}
    </button>
    <button kendoButton class="close-button" themeColor="primary" (click)="doAction('closeDialogThanksWithoutPayment')" >
      {{ 'close_application' | translate | uppercase }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
<!--dialog confirm payment-->
<kendo-dialog
  class="dialog-rt dialog-star-not-choosen"
  *ngIf="dialogProcedeWithPaymentActive"
  (close)="doAction('closeDialogConfirmPayment')"
  [minWidth]="300"
  [width]="300"
>
  <kendo-dialog-titlebar>{{ 'thanks_rate' | translate }}</kendo-dialog-titlebar>
  <p style="margin: 30px; text-align: center;">
    {{ 'thanks_rate_tip' | translate }}
  </p>
  <kendo-dialog-actions>
    <button kendoButton class="payment-button" themeColor="primary" (click)="doAction('procedeToPayment')" >
      {{ 'go_to_payment' | translate | uppercase }}
    </button>
    <button kendoButton class="confirm-button" themeColor="primary" (click)="doAction('newVote')" >
      {{ 'skip_payment_again' | translate | uppercase }}
    </button>
    <button kendoButton class="close-button" themeColor="primary" (click)="doAction('closeDialogConfirmPayment')" >
      {{ 'close_application' | translate | uppercase }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

