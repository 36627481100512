import { HttpClient, HttpHeaders} from '@angular/common/http';
import { booleanAttribute, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, map, throwError} from 'rxjs';
import { HttpUtils } from 'src/app/shared/http-utils';
import { LocalStorageService, UserLocalStorageObject } from 'src/app/shared/local-storage.service';
import { environment } from 'src/environments/environment';

class LoginReturnData {
  inhouse!: string;
  cashTipUser: string;
}

@Injectable({
  providedIn: 'root'
})

export class GuestLoginService {

  userData = new BehaviorSubject<UserLocalStorageObject|null>(null);

  constructor(private _http: HttpClient, private _localStorage: LocalStorageService, private _router: Router) { }


  public getSessionToken(): Observable<any> {
    return this._http.get<any>(environment.serverUrl + '/customer/getToken').pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  // methods
  loginPost(surname: string, room: string) {
    return this._http.post<LoginReturnData>(environment.serverUrl + '/customer/checkCheckIn', { surname, room }, { headers: HttpUtils.createHeaders() }).pipe(
        map(resData => {
          if (typeof resData.cashTipUser === "string" && resData.cashTipUser.toLowerCase() === 'true') {
            localStorage.setItem('cashTipUser', 'true');
          }
          return resData;
        }),
      );
  }
  autoLogin() {
  }

  logout() {
    localStorage.clear();
    this._router.navigate(['guest/enter']);

  }
}
