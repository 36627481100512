import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { AdminLoginService } from '../login/admin-login.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent {

  @ViewChild('loginForm')
  loginForm!: NgForm;

  @ViewChild("password")
  public passwordField!: TextBoxComponent;

  public recoverPasswordFormGroup: FormGroup;
  public errorMessage!: string;
  public userNotFoundActive: boolean;
  public locale: string;

  constructor(private _formBuilder: FormBuilder, private _router: Router, private _route: ActivatedRoute,
    private _loginService: AdminLoginService, private _sharedService: SharedService
    ) {
      this._sharedService.manageLocale();
      this.recoverPasswordFormGroup = this._formBuilder.group({
        'email': ['', [<any>Validators.email, <any>Validators.required]],
      });
  }

  public ngOnInit(): void {
    console.log('navigator.language', navigator.language);
    console.log('navigator.languages', navigator.languages);
    switch (navigator.language.toLowerCase()) {
      case 'it':
        this.locale = 'it';
        break;
      case 'de':
        this.locale = 'de';
        break;
      default:
        this.locale = 'en'
        break;
    }
  }

  public onRecoverPasswordFormSubmit(): void {
    this.recoverPasswordFormGroup.markAllAsTouched();
    if (this.recoverPasswordFormGroup.valid) {
      this._doRecoverPassword()
    }
  }

  private _doRecoverPassword() {

  }


  public openLoginPage(): void {
    this._router.navigate(['/admin/login']);
  }

}
