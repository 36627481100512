<div class="main-container">
  <app-main-header [showGoHome]="true" (goHome)="goHomeHandler()"></app-main-header>
  <div class="content">
    <h2>{{ 'payment_error' | translate }}</h2>
    <b>{{ 'dear_guest' | translate: { guestName: (voteObject.lastName | titlecase) } }},</b><br/>
    {{ 'payment_retry_msg' | translate: { retryButton: ('payment_retry' | translate), closeButton: ('close_page' | translate) } }} <br><br>
    {{ 'payment_greetings' | translate}}<br/><br/>
    {{ 'staff_name' | translate }}
    <div class="button-row">
      <button kendoButton class="confirm-button" themeColor="primary" (click)="doAction('procedeWithPayment')" >
        {{ 'payment_retry' | translate }}
      </button>
      <button kendoButton class="close-button" themeColor="primary" (click)="doAction('closePage')" >
        {{ 'close_page' | translate }}
      </button>
    </div>
  </div>
  <app-main-footer></app-main-footer>
</div>
