import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpUtils } from 'src/app/shared/http-utils';
import { environment } from 'src/environments/environment';
import { Employee } from '../shared/models/employee.model';
import { Department } from '../shared/models/department.model';


enum EndpointRoutes {
  GET_DEPARTMENTS = '/departments/get',
  GET_EMPLOYEES = '/employees/get'
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private _http: HttpClient) { }

  public getDepartmentsFromApi(): Observable<Department[]> {
    return this._http.get<Department[]>(environment.serverUrlAdmin + EndpointRoutes.GET_DEPARTMENTS, { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getEmployeesFromApi(): Observable<Employee[]> {
    return this._http.get<Employee[]>(environment.serverUrlAdmin + EndpointRoutes.GET_EMPLOYEES, { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

}
