<div class="main-content">
  <div class="maintitle">Stars Report</div>
  <div class="container">
    <div class="grid-wrapper">
      <kendo-grid
        style="max-height: 700px; margin-top: 10px !important;"
          [data]="gridData"
          [pageSize]="state.take"
          [skip]="state.skip"
          [sort]="state.sort"
          [filter]="state.filter"
          [sortable]="sortingSettings"
          [pageable]="{
            info: pagingSettings.info,
            type: pagingSettings.pagerTypes,
            pageSizes: pagingSettings.pageSizes,
            previousNext: pagingSettings.previousNext,
            position: pagingSettings.position,
            responsive: pagingSettings.responsive
          }"
          [reorderable]="true"
          [resizable]="true"
          filterable="menu"
          [columnMenu]="{ filter: true }"
          (dataStateChange)="dataStateChange($event)"
        >
        <ng-template kendoGridToolbarTemplate class="grid-toolbar">
          <div style="display:flex; width:100%; overflow-x: scroll;">
            <div class="search-bar" fxLayout="row" fxLayoutAlign="none" fxLayoutGap="20">
              <div class="search-field" fxLayout="column" fxLayoutAlign="none" fxLayoutGap="2">
                <span class="k-label"><b>Employees</b></span>
                <kendo-combobox #employeeId
                  [data]="employeesArray"
                  textField="text"
                  valueField="value"
                  [filterable]="true"
                  (filterChange)="handleEmployeeFilter($event)"
                  (selectionChange)="handleEmployeeSelection($event)"
                  placeholder="All employees"
                  [style]="'width:180px'"
                ></kendo-combobox>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="none" fxLayoutGap="2">
                <span class="k-label"><b>Departments</b></span>
                <kendo-combobox #departmentId
                  [data]="departmentsArray"
                  textField="text"
                  valueField="value"
                  [filterable]="true"
                  (filterChange)="handleDepartmentFilter($event)"
                  (selectionChange)="handleDepartmentSelection($event)"
                  placeholder="All departm."
                  [style]="'width:180px'"
                ></kendo-combobox>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="none" fxLayoutGap="2">
                <span class="k-label"><b>Type</b></span>
                <kendo-combobox #typeId
                  [data]="typesArray"
                  textField="text"
                  valueField="value"
                  [filterable]="true"
                  (filterChange)="handleTypeFilter($event)"
                  (selectionChange)="handleTypeSelection($event)"
                  placeholder="All types"
                  [style]="'width:180px'"
                  [(ngModel)]="type"
                ></kendo-combobox>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="none" fxLayoutGap="2">
                <span class="k-label"><b>From</b></span>
                <kendo-datepicker
                  name="dateFrom"
                  [(value)]="dateFrom"
                  [max]="dateFromMax"
                  [placeholder]="''"
                  [style]="'width:150px'"
                  [format]="'dd-MM-yyyy'"
                ></kendo-datepicker>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="none" fxLayoutGap="2">
                <span class="k-label" ><b>To</b></span>
                <kendo-datepicker
                  name="dateTo"
                  [(value)]="dateTo"
                  [max]="dateToMax"
                  [placeholder]="''"
                  [style]="'width:150px'"
                  [format]="'dd-MM-yyyy'"
                ></kendo-datepicker>
              </div>
              <div class="commands-bar" fxLayout="row" fxLayoutAlign="none" fxLayoutGap="2">
                <button kendoButton themeColor="primary" icon="search" (click)="applyFilter()">Search</button>
                <button kendoGridExcelCommand
                  [svgIcon]="fileExcelIcon"
                  size="large"
                  fillMode="link"
                  >
                  Export
                </button>
              </div>
            </div>
          </div>
        </ng-template>
        <kendo-grid-column
          field="name"
          [title]="typeColumnLabel"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="200"
          [minResizableWidth]="200"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="one"
          title="1S"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="112"
          [minResizableWidth]="112"
        >
          <ng-template
            kendoGridHeaderTemplate
            let-column
            let-columnIndex="columnIndex"
          >
            <span class="star-icon k-icon k-i-star yellow"></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="two"
          title="2S"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="112"
          [minResizableWidth]="112"
        >
          <ng-template
            kendoGridHeaderTemplate
            let-column
            let-columnIndex="columnIndex"
          >
            <span class="star-icon k-icon k-i-star yellow"></span>
            <span class="star-icon k-icon k-i-star yellow"></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="three"
          title="3S"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="124"
          [minResizableWidth]="124"
        >
          <ng-template
            kendoGridHeaderTemplate
            let-column
            let-columnIndex="columnIndex"
          >
            <span class="star-icon k-icon k-i-star yellow"></span>
            <span class="star-icon k-icon k-i-star yellow"></span>
            <span class="star-icon k-icon k-i-star yellow"></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="four"
          title="4S"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="136"
          [minResizableWidth]="136"
        >
          <ng-template
            kendoGridHeaderTemplate
            let-column
            let-columnIndex="columnIndex"
          >
            <span class="star-icon k-icon k-i-star yellow"></span>
            <span class="star-icon k-icon k-i-star yellow"></span>
            <span class="star-icon k-icon k-i-star yellow"></span>
            <span class="star-icon k-icon k-i-star yellow"></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="five"
          title="5S"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="148"
          [minResizableWidth]="148"
        >
          <ng-template
            kendoGridHeaderTemplate
            let-column
            let-columnIndex="columnIndex"
          >
            <span class="star-icon k-icon k-i-star yellow"></span>
            <span class="star-icon k-icon k-i-star yellow"></span>
            <span class="star-icon k-icon k-i-star yellow"></span>
            <span class="star-icon k-icon k-i-star yellow"></span>
            <span class="star-icon k-icon k-i-star yellow"></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="countRating"
          title="count vote"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="156"
          [minResizableWidth]="156"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="average"
          title="avg. stars"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="156"
          [minResizableWidth]="156"
        >
        </kendo-grid-column>
        <kendo-grid-excel fileName="report.xlsx" [fetchData]="excelExportData">
          <kendo-excelexport-column field="name" [title]="typeColumnLabel">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="one" title="1 Star">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="two" title="2 Stars">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="three" title="3 Stars">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="four" title="4 Stars">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="five" title="5 Stars">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="countRating" title="Count Vote">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="averageForExport" title="Avg. Stars" >
          </kendo-excelexport-column>
        </kendo-grid-excel>
      </kendo-grid>
      <div *ngIf="loading" class="k-i-loading" style="height: 100vh; width: 100vw;"></div>
    </div>
  </div>
</div>
