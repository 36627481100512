import { Component, ViewChild } from '@angular/core';
import { CellOptions, ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, MultipleSortSettings, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { AdminService } from '../../admin.service';
import { DatePipe, formatCurrency } from '@angular/common';
import { SVGIcon, infoCircleIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { ReportElement, ReportService } from './report.service';
import { StarsReportService } from '../stars-report/stars-report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public dateFromMax: Date = new Date();
  public dateToMax: Date = new Date();
  public selectedEmployeeId?: string;
  public selectedDepartmentId?: string;
  public selectedTypeId?: string;
  public guestName: string = '';
  public dateFrom : Date = new Date(new Date().setDate(new Date().getDate()-31)); //last 30 days
  public dateTo : Date = new Date();

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public reportArray: ReportElement[] = [];
  public pageCode: string;

  public infoCircleIcon: SVGIcon = infoCircleIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'bottom';

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 10,
    responsive: false,
    pageSizes: [
      5,
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings: MultipleSortSettings = {
    allowUnsort: true,
  };

  public amountCellOptions: CellOptions = {
    format: '#,0.00',
  }

public state: State = {
  skip: 0,
  take: this.pagingSettings.pageSize,
  // Initial filter descriptor
};

  public employeesSource: Array<{ text: string; value: string }> = [];
  public employeesArray: Array<{ text: string; value: string }>;

  public departmentsSource: Array<{ text: string; value: string }> = [];
  public departmentsArray: Array<{ text: string; value: string }>;

  public typesSource: Array<{ text: string; value: string }> = [
    { text: 'Employee', value: 'employee' },
    { text: 'Department', value: 'department' },
    { text: 'Team Cavallino Bianco', value: 'company' },
    { text: 'End-of-Season Trip', value: 'trip' },
  ];
  public typesArray: Array<{ text: string; value: string }>;

  public loading = false;

  constructor(private _reportService: ReportService, private _starsReportService: StarsReportService, private _adminService: AdminService, private _datepipe: DatePipe) {

    };

  public ngOnInit(): void {

    this._adminService.getDepartmentsFromApi().subscribe(result => {
      result.forEach(rec => {
        const elem  = { text: rec.name, value: rec.id.toString() };
        this.departmentsSource.push(elem);
        this.departmentsArray = this.departmentsSource;
      });
      // console.log('result', result);
    });

    this._adminService.getEmployeesFromApi().subscribe(result => {
      const sortedResult = result.sort((a, b) => a.firstName.localeCompare(b.firstName));
      sortedResult.forEach(rec => {
        const elem  = { text: rec.firstName + ' ' + rec.lastName, value: rec.id.toString() };
        this.employeesSource.push(elem);
        this.employeesArray = this.employeesSource;
      });
    });

    this.typesArray = this.typesSource;

    this._setGridData();

  }

  private _setGridData() {
    this.loading = true;
    const dateFrom = moment(this.dateFrom).format('YYYY-MM-DD');
    const dateTo = moment(this.dateTo).format('YYYY-MM-DD');
    const employeeId = this.selectedEmployeeId;
    const departmentId = this.selectedDepartmentId;
    const guestName = this.guestName;
    const type = this.selectedTypeId;

    this._reportService.getReport(employeeId, departmentId, guestName, type, dateFrom, dateTo).subscribe(result => {
      if (result) {
//        console.log('result:', result);
        result.forEach(item => {
          if (item.employeeLastName || item.employeeFirstName) {
            item.employeeFullName = item.employeeFirstName + ' ' + item.employeeLastName;
          } else {
            item.employeeFullName = '-';
          }
          item.one = 0;
          item.two = 0;
          item.three = 0;
          item.four = 0;
          item.five = 0;
          /*
          console.log('employeeId', item.employeeId);
          console.log('departmendId', item.departmentId);
          console.log('guestName', guestName);
          console.log('ratingType', item.ratingType);
          console.log('dateFrom', dateFrom);
          console.log('dateTo', dateTo);
          */
          this._starsReportService.getStarsReport(item.employeeId, item.departmentId, guestName, item.ratingType, dateFrom, dateTo).subscribe(resultS => {
           // console.log('resultS', resultS);
            resultS.forEach(itemS => {
              item.one = item.one + itemS.one;
              item.two = item.two + itemS.two;
              item.three = item.three + itemS.three;
              item.four = item.four + itemS.four;
              item.five = item.five + itemS.five;
            });
            item.countRating = item.one + item.two + item.three + item.four + item.five;
            if (item.countRating > 0) {
              item.average = parseFloat(((item.one * 1 + item.two * 2 + item.three * 3 + item.four * 4 + item.five * 5) / item.countRating).toFixed(2));
              item.averageForExport = (item.one * 1 + item.two * 2 + item.three * 3 + item.four * 4 + item.five * 5) / item.countRating;
            } else {
              item.average = item.averageForExport = 0;
            }
          });
          // while (wait); //don't execute next loop if wait is true
        });
        // console.log('myResult:', result);
        this.reportArray = result;
        this.gridData = process(this.reportArray, this.state);
        this.excelExportData = this.excelExportData.bind(this);
        this.loading = false;
      }
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.gridData = process(this.reportArray, this.state);
    setTimeout(() => {
      this.loading = false;
    }, 100);
  }

  public excelExportData(): ExcelExportData {
    const excelData = process(this.reportArray, {
      sort: this.state.sort,
      filter: this.state.filter,

    });
    const result: ExcelExportData = {
      data: excelData.data
    };
    return result;
  }

  public applyFilter() {
    if (!this.dateFrom) this.dateFrom = new Date(new Date().setDate(new Date().getDate()-31)); //last 30 days
    if (!this.dateTo) this.dateTo = new Date(new Date().setDate(new Date().getDate()-1)); //yesterday
    this.state.skip = 0;
    this.state.take = this.pagingSettings.pageSize;
    this._setGridData();
  }

  public handleEmployeeFilter(value: any) {
    this.employeesArray = this.employeesSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleEmployeeSelection(data: any) {
    if (data) {
      this.selectedEmployeeId = data.value;
    } else {
      this.selectedEmployeeId = undefined;
    }
  }

  public handleDepartmentFilter(value: any) {
    this.departmentsArray = this.departmentsSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleDepartmentSelection(data: any) {
    if (data) {
      this.selectedDepartmentId = data.value;
    } else {
      this.selectedDepartmentId = undefined;
    }
  }

  public handleTypeFilter(value: any) {
    this.typesArray = this.typesSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleTypeSelection(data: any) {
    if (data) {
      this.selectedTypeId = data.value;
    } else {
      this.selectedTypeId = undefined;
    }
  }

}
