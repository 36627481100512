<ng-container *ngIf="showSuccessPage">
<div class="main-container">
  <app-main-header [showGoHome]="true" (goHome)="goHomeHandler()"></app-main-header>
  <div class="content">
    <h2>{{ 'payment_successful' | translate }}</h2>
    <b>{{ 'dear_guest' | translate: { guestName: (voteObject.lastName | titlecase) } }},</b><br/>
    {{ 'payment_successful_msg' | translate: { amount: voteObject.tipAmount | number: '1.1-2' } }} <br><br>
    {{ 'payment_greetings' | translate}}<br/><br/>
    {{ 'staff_name' | translate }}
    <div class="button-row">
      <button kendoButton class="confirm-button" themeColor="primary" (click)="doAction('newVote')" >
        {{ 'rate_again' | translate }}
      </button>
      <button kendoButton class="close-button" themeColor="primary" (click)="doAction('closePage')" >
        {{ 'close_application' | translate }}
      </button>
    </div>
  </div>
  <app-main-footer></app-main-footer>
</div>
</ng-container>
<ng-container *ngIf="showVerifyErrorPage">
  <div class="main-container">
    <app-main-header [showGoHome]="true" (goHome)="goHomeHandler()"></app-main-header>
    <div class="content">
      <h2>{{ 'payment_error' | translate }}</h2>
      <b>{{ 'dear_guest' | translate: { guestName: (voteObject.lastName | titlecase) } }},</b><br/>
      {{ 'payment_error_msg' | translate: { amount: voteObject.tipAmount | number: '1.1-2' } }} <br><br>
      {{ 'payment_greetings' | translate}}<br/><br/>
      {{ 'staff_name' | translate }}
      <div class="button-row">
        <button kendoButton class="confirm-button" themeColor="primary" (click)="doAction('newVote')" >
          {{ 'rate_again' | translate }}
        </button>
        <button kendoButton class="close-button" themeColor="primary" (click)="doAction('closePage')" >
          {{ 'close_page' | translate }}
        </button>
      </div>
    </div>
    <app-main-footer></app-main-footer>
  </div>
  </ng-container>
