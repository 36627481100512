<kendo-card [orientation]="'horizontal'" [width]="'100%'">
  <ng-container *ngIf="scrollViewItems?.length > 0; else noItems">
    <div class="slideshow-container">
      <div *ngFor="let image of scrollViewItems; let i = index" class="my-slides fade">
        <img src="{{image.url}}"
             alt="{{ image.alt || ('Cavallino Pic' + i )}}"
             [ngStyle]="{  width: '100%', height: '100%', objectFit: 'cover' }"
        />
      </div>
      <div class="arrows-container">
        <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
        <a class="next" (click)="plusSlides(1)">&#10095;</a>
      </div>
    </div>
  </ng-container>
  <ng-template #noItems>
    <img
      src="assets/media/images/user_placeholder.png" alt="no pics"
      [width]="'100%'"
      [height]="'200px'"
      [ngStyle]="{  width: '100%', height: '200px', objectFit: 'cover' }"
      draggable="false"
    />
  </ng-template>
</kendo-card>
