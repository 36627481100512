<div class="main-container">
  <div class="login-container">
    <div class="left-column">
      &nbsp;
    </div>
    <div class="logo">
      <img src="../../assets/media/images/logo_login.png" title="Cavallino Bianco" alt="Cavallino Bianco">
    </div>
    <div class="main-title">
      Rate<br>&<br>Reward
    </div>
    <div class="introduction">
      <div style="background-color: #8f7f75; padding: 20px; margin-bottom:20px;">
      <div class="title">
        {{ 'introduction' | translate }}
      </div>
      <div class="text">
        {{ 'introduction_msg' | translate}}
      </div>
        <div class ="signature">
          <img src="../../assets/media/images/signature.png" title="Signature" alt="signature">
        </div>
      </div>
      <div class="button-wrapper">
        <button kendoButton themeColor="primary" type="button" class="button-primary" (click)="start()">{{ 'start' | translate }}</button>
      </div>
    </div>
    <div class="footer-container">
      <div kendoTypography variant="p" fontSize="sm" textAlign="center" class="poweredby-footer">
        <span>Powered by</span><span><b>Cavallino Bianco Family Spa Grand Hotel</b></span>
        <span><a href="mailto:ralph@cavallino-bianco.com" target="_blank">Email</a></span>
        <span><a href="//www.cavallino-bianco.com" target="_blank">Website</a></span>
        <span><b>IT00633280219</b></span>
      </div>
    </div>
  </div>
</div>


