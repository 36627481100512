import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminComponent {

  constructor(private _router: Router) { }

  ngOnInit() {

    const url = this._router.url;
    switch (url) {
      case '/admin':
        this._router.navigate(['admin/login']);
        break;
    }
  }
}
