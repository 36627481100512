<div class="container">
  <div class="title">{{ 'trip_title' | translate }}</div>
  <div class="images-container">
    <app-scrollview-card
      [scrollViewItems]="scrollViewCards"
      [ngStyle]="{ width: '100%', maxWidth: 'none' }"
    ></app-scrollview-card>
  </div>
  <div class="content">
    <form [formGroup]="tripForm">
      <div class="row">
        <div class="label col-5">{{ 'reward' | translate }}</div>
        <div class="input col-7">
          <kendo-formfield>
            <kendo-numerictextbox
              #tipAmount
              format="c0"
              [min]="0.01"
              formControlName="tipAmount"
              [format]="formatOptions"
            >
            </kendo-numerictextbox>
            <kendo-formerror>{{ 'amount_error' | translate }}</kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
      <div class="row">
        <div class="label col-5">{{ 'comment' | translate }}</div>
        <div class="input col-7">
          <kendo-formfield>
            <kendo-textarea
              #comment
              [style.width.perc]="100"
              formControlName="comment"
              [rows]="5"
              flow="horizontal"
              placeholder="{{ 'comment_placeholder' | translate }}"
              resizable="none"
              [maxlength]="commentMaxLength"
              (valueChange)="onCommentValueChange($event)"
            >
            </kendo-textarea>
            <div>
              <kendo-textarea-suffix class="custom-styling">
                <span class="counter">{{ commentCounter }}</span>
                <button
                  kendoButton
                  class="suffix-clear-button"
                  fillMode="clear"
                  [svgIcon]="xSvg"
                  (click)="clearValue()"
                ></button>
              </kendo-textarea-suffix>
            </div>
          </kendo-formfield>
        </div>
      </div>
      <div class="row">
        <div class="label col-5">Email</div>
        <div class="input col-7">
          <kendo-formfield>
            <input
              #emailPaymentReceipt
              class="k-input k-input-solid k-rounded-md"
              type="email"
              name="email"
              autocomplete="on"
              maxlength="100"
              formControlName="emailPaymentReceipt"
              [required]="(this.tripForm.controls['tipAmount'].value > 0)"
            />
            <kendo-formerror>{{ 'invalid_mail_msg' | translate }}</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="info col-12">({{ 'receipt_mail_msg' | translate }})</div>
      </div>
      <div class="button-row">
        <button kendoButton class="button-primary" (click)="directPayment()">
          {{ 'proceed' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
