import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GuestLoginService } from '../../login/guest-login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent {
  public room: string;
  public lastname: string;
  private _isLogged: boolean = false;

  @Output() resetVote: EventEmitter<undefined> = new EventEmitter();
  @Output() goHome: EventEmitter<undefined> = new EventEmitter();

  @Input() showGoHome: boolean = false;

  @Output() goToPrevious: EventEmitter<undefined> = new EventEmitter();
  @Input() currentStep: number = 0;

  constructor(private _loginService: GuestLoginService, private _router: Router) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('isLogged')) {
      this._isLogged = Boolean(localStorage.getItem('isLogged'));
      if (this._isLogged) {
        if (localStorage.getItem('room')) {
          this.room = localStorage.getItem('room') || '';
        }
        if (localStorage.getItem('lastname')) {
          this.lastname = localStorage.getItem('lastname') || '';
        }
      }
    }
  }

  logout() {
    this._loginService.logout();
  }

  reload() {
    this.resetVote.emit();
  }

  home() {
    this.goHome.emit();
  }

  goToPreviousStep() {
    this.goToPrevious.emit();
  }

}
