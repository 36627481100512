import { HttpHeaders } from '@angular/common/http';

export class HttpUtils {

	public static createHeaders(): HttpHeaders {
		const accessToken = localStorage.getItem('X-Access-Token');
    const httpHeader = new HttpHeaders({'X-Access-Token': (accessToken) ? accessToken : ''});
    return httpHeader;
	}
}
