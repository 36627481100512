import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { GuestLoginService } from './guest-login.service';
import { LoadingService } from 'src/app/shared/loading-spinner/loading.service';
import { SharedService } from 'src/app/shared/shared.service';
import { GuestService } from '../guest.service';
import { Department } from 'src/app/shared/models/department.model';
import { Employee } from 'src/app/shared/models/employee.model';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-guest-login',
  templateUrl: './guest-login.component.html',
  styleUrls: ['./guest-login.component.scss']
})
export class GuestLoginComponent {

  @ViewChild('loginForm')
  loginForm!: NgForm;

  public loginFormGroup: FormGroup;
  public errorMessage!: string;
  public dialogGuestNotFoundActive: boolean;
  public locale: string;
  // private _validRoom:string = '100';
  // private _validLastName:string = 'ralf';

  public privacyHTMLMessage: string;
  public termsHTMLMessage: string;

  constructor(private _formBuilder: FormBuilder, private _router: Router, private _route: ActivatedRoute,
    private _loginService: GuestLoginService, private _sharedService: SharedService, private _guestService: GuestService,
    private _translate: TranslateService
    ) {
      this._sharedService.manageLocale();
      this.loginFormGroup = this._formBuilder.group({
        'room': ['', <any>Validators.required],
        'lastname': ['', <any>Validators.required],
        'acceptPrivacy': [false, <any>Validators.requiredTrue],
        'acceptTerms': [false, <any>Validators.requiredTrue]
      });

      this._translate.get('privacy_title').subscribe((privacyTitle: string) => {
        this.privacyHTMLMessage = this._translate.instant('privacy_msg', {
          privacyHyperlink: '<a href="' + this._getPrivacyLink() + '" target="_blank">' + privacyTitle + '</a>'
        });
      });

      this._translate.get('terms_title').subscribe((termsTitle: string) => {
        this.termsHTMLMessage = this._translate.instant('terms_conditions_msg', {
          termsHyperlink: '<a href="' + this._getTermsLink() + '" target="_blank">' + termsTitle + '</a>'
        });
      });

  }

  public ngOnInit(): void {
     switch (this._translate.currentLang) {
      case 'it':
        this.locale = 'it';
        break;
      case 'de':
        this.locale = 'de';
        break;
      default:
        this.locale = 'en'
        break;
    }
    this._loginService.getSessionToken().subscribe(res => {
      if (res.sessionToken) {
        localStorage.setItem('X-Access-Token', res.sessionToken);
      }
    });
  }

  public onLoginFormSubmit(): void {
    this.loginFormGroup.markAllAsTouched();
    if (this.loginFormGroup.valid) {
      this._doLogin(
        (this.loginFormGroup.value.room + '').trim(),
        (this.loginFormGroup.value.lastname + '').trim(),
        '/guest/main',
        )
    }
  }
  private _doLogin(room: string, lastname: string, redirectToPage: string) {
    this._loginService.loginPost(lastname.toLowerCase(), room.toLowerCase()).subscribe(res => {
      if (res.inhouse === 'yes') {
        localStorage.setItem('locale', this.locale);
        localStorage.setItem('isLogged', 'true');
        localStorage.setItem('lastname', lastname);
        localStorage.setItem('room', room);
        localStorage.setItem('currentStep', '0');
        localStorage.setItem('step1Completed', 'false');
        localStorage.setItem('step2Completed', 'false');
        localStorage.setItem('step3Completed', 'false');
        forkJoin([
          this._guestService.getDepartmentsFromApi(),
          this._guestService.getEmployeesFromApi(),
        ]).subscribe(result => {
          if (result[0]) {
            localStorage.setItem('departments', JSON.stringify(result[0]));
          }
          if (result[1]) {
            localStorage.setItem('employees', JSON.stringify(result[1]));
          }
          this.dialogGuestNotFoundActive = false;
          this._router.navigate([redirectToPage]);
        });
      } else {
        this.dialogGuestNotFoundActive = true;
      }
    });
  }

  public openIntroductionPage(): void {
    this._router.navigate(['guest/introduction']);
  }

  public closeDialogGuestNotFound() {
    this.dialogGuestNotFoundActive = false;
  }

  private _getPrivacyLink(): string {
    switch (this._translate.getDefaultLang()) {
      case 'it':
        return 'https://www.cavallino-bianco.com/it/informazione/privacy/49-0.html';
      case 'de':
        return 'https://www.cavallino-bianco.com/de/information/datenschutzerkl%C3%A4rung/49-0.html';
      default:
        return 'https://www.cavallino-bianco.com/en/information/privacy/49-0.html';
    }
  }

  private _getTermsLink(): string {
    switch(this._translate.getDefaultLang()) {
      case 'it':
        return 'https://cavallinobianco2018.live-edit.amplifier.love/files/content/107092_12856_2_0/r-r-app.-agb-ita-final-mkt.pdf';
      case 'de': return 'https://cavallinobianco2018.live-edit.amplifier.love/files/content/107092_12856_1_0/r-r-app.-agb-deu-final-mkt.pdf';
      default:
        return 'https://cavallinobianco2018.live-edit.amplifier.love/files/content/107092_12856_3_0/rr-app.-agb-eng-final-mkt.pdf';
    }
  }

}
