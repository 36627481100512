import { Component, Input, Output } from '@angular/core';
import { GuestLoginService } from '../login/guest-login.service';
import { StepperActivateEvent } from '@progress/kendo-angular-layout';
import { Vote } from 'src/app/shared/models/vote.model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  // public
  public steps = [];
  public cashTipRegistration: boolean;
  @Input() public currentStep: number = 0;
  public voteObject: Vote = new Vote;
  //private
  private _isLogged: boolean = false;

  constructor(private _loginService: GuestLoginService) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('isLogged')) {
      this._isLogged = Boolean(localStorage.getItem('isLogged'));
      this.currentStep = Number(localStorage.getItem('currentStep'));
      if (this._isLogged) {
        this.cashTipRegistration = localStorage.getItem('cashTipUser') === 'true';
        this.steps = [
          { label: "start_stepper", current: true },
          { label: "choose", disabled: true, current: false },
          { label: this.cashTipRegistration ? "cash_tip_stepper" : "rate_and_reward", disabled: true, current: false }
        ];
        this.resetVoteHandler();
      } else {
        this._loginService.logout();
      }
    } else {
      this._loginService.logout();
    }
  }

  public activate(e: StepperActivateEvent): void {
    console.log('activate', e);
  }

  public currentStepChange(e: number): void {
    console.log('currentStepChange', e);
  }

  public nextStepVoteHandler(vote: Vote) {
    this.voteObject = vote;
    switch (this.voteObject.type) {
      case 'employee':
      case 'department':
        this.currentStep += 1;
        this.steps[this.currentStep - 1].current = false;
        this.steps[this.currentStep].disabled = false;
        this.steps[this.currentStep].current = true;
        break;
      case 'trip':
      case 'company':
        this.currentStep = 2;
        this.steps[0].disabled = false;
        this.steps[0].current = false;
        this.steps[1].current = false;
        this.steps[1].disabled = true;
        this.steps[2].current = true;
        this.steps[2].disabled = false;
        break;
    }
  }

  public goToPreviousStep() {
    switch (this.voteObject.type) {
      case 'employee':
      case 'department':
        if (this.currentStep === 0) break;
        this.steps[this.currentStep].current = false;
        this.steps[this.currentStep].disabled = true;
        this.currentStep -= 1;
        this.steps[this.currentStep].current = true;
        this.steps[this.currentStep].disabled = false;
        break;
      case 'trip':
      case 'company':
        if (this.currentStep !== 2) break;
        this.currentStep = 0;
        this.steps[2].current = false;
        this.steps[2].disabled = true;
        this.steps[1].current = false;
        this.steps[1].disabled = true;
        this.steps[this.currentStep].current = true;
        this.steps[this.currentStep].disabled = false;
        break;
    }
  }

  public resetVoteHandler() {
    // reset token
    this._loginService.getSessionToken().subscribe(res => {
      if (res.sessionToken) {
        localStorage.setItem('X-Access-Token', res.sessionToken);
      }
    });
    this.voteObject = new Vote;
    this.currentStep = 0;
    this.steps.forEach(step => {
      step.current = false;
      step.disabled = true;
    });
    this.steps[0].current = true;
    this.steps[0].disabled = false;
    this.voteObject.lastName = localStorage.getItem('lastname');
    this.voteObject.room = localStorage.getItem('room');
    this.voteObject.language = localStorage.getItem('locale');
  }

}
