import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Menu } from './models/menu.model';

class AppLocalStorageObject {
  locale?: string;
}

export class UserLocalStorageObject {
  lastname: string;
  room: string;
  sessionToken: string
  locale: string
  constructor(lastname: string, room: string, sessionToken: string) {
    this.lastname = lastname;
    this.room = room;
    this.sessionToken = sessionToken;
    switch (navigator.language.toLowerCase()) {
      case 'it':
        this.locale = 'it';
        break;
      case 'de':
        this.locale = 'de';
        break;
      default:
        this.locale = 'en'
        break;
    }
  }
}

export class AdminUserLocalStorageObject {
  id: number;
  sessionToken: string;
  locale: string;
  constructor(id: number, sessionToken: string, locale:string) {
    this.id = id;
    this.sessionToken = sessionToken;
    this.locale = locale;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public userLocalStorageInserted = new BehaviorSubject(this.getUserLocalStorageObject());
  public adminUserLocalStorageInserted = new BehaviorSubject(this.getAdminUserLocalStorageObject());

  constructor() { }

  private _getLocalStorage(uniqueId: string) {
    return localStorage.getItem(uniqueId);
  }

  private _setLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  /**
   * USER LOCAL STORAGE
  */

  public userLocalStorageExist(): boolean {
    return (this._getLocalStorage(environment.userSessionUniqueId) ? true : false);
  }

  public getUserLocalStorageObject(): UserLocalStorageObject {
    const currentLocalStorage = this._getLocalStorage(environment.userSessionUniqueId);
    const object = JSON.parse(currentLocalStorage || '{}');
    return object;
  }

  public setUserLocalStorage<T extends keyof UserLocalStorageObject>(key: T, value: any) {
    const localStorageObject: UserLocalStorageObject = JSON.parse(this._getLocalStorage(environment.userSessionUniqueId) || '{}');
    localStorageObject[key] = value;
    this._setLocalStorage(environment.userSessionUniqueId, JSON.stringify(localStorageObject));
    this.userLocalStorageInserted.next(localStorageObject);
  }

   /**
   * ADMIN USER LOCAL STORAGE
  */

   public adminUserLocalStorageExist(): boolean {
    return (this._getLocalStorage(environment.userSessionUniqueId) ? true : false);
  }

  public getAdminUserLocalStorageObject(): AdminUserLocalStorageObject {
    const currentLocalStorage = this._getLocalStorage(environment.userSessionUniqueId);
    const object = JSON.parse(currentLocalStorage || '{}');
    return object;
  }

  public setAdminUserLocalStorage<T extends keyof AdminUserLocalStorageObject>(key: T, value: any) {
    const localStorageObject: AdminUserLocalStorageObject = JSON.parse(this._getLocalStorage(environment.userSessionUniqueId) || '{}');
    localStorageObject[key] = value;
    this._setLocalStorage(environment.userSessionUniqueId, JSON.stringify(localStorageObject));
    this.adminUserLocalStorageInserted.next(localStorageObject);
  }

  /**
   * APP LOCAL STORAGE
   */
  public appLocalStorageExist(): boolean {
    return (this._getLocalStorage(environment.appSessionUniqueId) ? true : false);
  }

  public getAppLocalStorageObject(): AppLocalStorageObject {
    const currentLocalStorage = this._getLocalStorage(environment.appSessionUniqueId);
    const object = JSON.parse(currentLocalStorage || '{}');
    return object;
  }

  public setAppLocalStorage<T extends keyof AppLocalStorageObject>(key: T, value: any) {
    let localStorageObject = new AppLocalStorageObject();
    if (this.appLocalStorageExist()) {
      localStorageObject = JSON.parse(this._getLocalStorage(environment.appSessionUniqueId) || '{}');
    }
    localStorageObject[key] = value;
    this._setLocalStorage(environment.appSessionUniqueId, JSON.stringify(localStorageObject));
  }

  public getActualMenuLs(hideDashboard: boolean = false) : Menu[] {
    const menuStr = localStorage.getItem('menus');
    const menus: Menu[] = JSON.parse(menuStr || '{}');
    menus.forEach((menuL1: Menu, indexL1: number) => {
      menuL1.hidden = false;
      if ((menuL1.code === 'dashboard') && (hideDashboard)) {
        menus.splice(indexL1,1);
      }
    })
    return menus;
  }


}
