import {Component, ElementRef, HostListener, Inject, Input, QueryList, ViewChildren} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-scrollview-card',
  templateUrl: './scrollview-card.component.html',
  styleUrls: ['./scrollview-card.component.scss']
})
export class ScrollviewCardComponent {
  @Input() public scrollViewItems = [];

  public isFullscreen: boolean;
  public slideIndex = 0;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.showSlides(this.slideIndex);
    },600);
  }

  plusSlides(n: number) {
    this.showSlides(this.slideIndex += n);
  }

  showSlides(n: number) {
    const slides = this.document.querySelectorAll(".my-slides");

    if (n > slides.length) this.slideIndex = 1;
    if (n < 1) this.slideIndex = slides.length;

    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i] as HTMLElement;
      slide.style.display = 'none';
    }

    const slide = slides[this.slideIndex - 1] as HTMLElement;
    slide.style.display = 'block';
  }

  toggleFullscreen(event: Event) {
    if (window.screen.width > 767) return;

    const imageElement = event.target as HTMLElement;

    if (this.isFullscreen) {
      this.closeFullscreen();
    } else {
      this.openFullscreen(imageElement);
    }
  }

  openFullscreen(elem: HTMLElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen({
        navigationUI: 'show'
      });
    }
    // @ts-ignore
    else if (elem.mozRequestFullScreen) {
      // @ts-ignore
      elem.mozRequestFullScreen();
    }
    // @ts-ignore
    else if (elem.webkitRequestFullscreen) {
      // @ts-ignore
      elem.webkitRequestFullscreen();
    }
    // @ts-ignore
    else if (elem.msRequestFullscreen) {
      // @ts-ignore
      elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    }
    // @ts-ignore
    else if (this.document.mozCancelFullScreen) {
      // @ts-ignore
      this.document.mozCancelFullScreen();
    }
    // @ts-ignore
    else if (this.document.webkitExitFullscreen) {
      // @ts-ignore
      this.document.webkitExitFullscreen();
    }
    // @ts-ignore
    else if (this.document.msExitFullscreen) {
      // @ts-ignore
      this.document.msExitFullscreen();
    }
  }
}
