<div class="container">
  <div class="title">{{ 'choose_department' | translate | uppercase }}</div>
  <div class="content">
    <kendo-grid
      [data]="gridData"
      [skip]="state.skip"
      [sort]="state.sort"
      [sortable]="false"
      [reorderable]="false"
      [resizable]="false"
      (edit)="selectDepartment($event)"
    >
    <ng-template kendoGridToolbarTemplate class="grid-toolbar">
      <div class="search-bar row" style="display:block; width:100%;">
        <div class="search-item">
          <input
            [style.width.px]="300"
            prefix="k-icon k-i-search"
            placeholder="{{ 'search_department' | translate }}"
            kendoTextBox
            (input)="onFilter($event)"
          />
        </div>
      </div>
    </ng-template>
    <kendo-grid-column
      field="name"
      title="{{ 'name' | translate }}"
      [headerClass]="'gridcolumn header'"
    >
    </kendo-grid-column>
    <kendo-grid-command-column title="{{ 'select' | translate }}">
      <ng-template kendoGridCellTemplate>
        <button kendoGridEditCommand icon="hand" size="small" fillMode="solid" class="button-primary">
          {{ 'select' | translate }}
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
  </div>
</div>

