import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vote } from 'src/app/shared/models/vote.model';
import { GuestService } from '../../guest.service';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { GuestLoginService } from '../../login/guest-login.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-vote-tip',
  templateUrl: './vote-tip.component.html',
  styleUrls: ['./vote-tip.component.scss'],
  providers: [DatePipe]
})
export class VoteTipComponent {
  @Input() voteObject: Vote = new Vote;
  @Output() nextStepVote: EventEmitter<Vote> = new EventEmitter();
  @Output() resetVote: EventEmitter<undefined> = new EventEmitter();

  public stars: number[];
  public departmentName: string;
  public employeeName: string;
  public employeePicturePath: string;
  public employeePictureName: string;
  public xSvg: SVGIcon = xIcon;
  public commentMaxLength: number = 500;
  public commentCharachtersCount: number;
  public commentCounter: string;
  public dialogStarNotFoundActive = false;
  public dialogProcedeWithPaymentActive = false;
  public dialogProcedeWithoutPaymentActive = false;
  public ratingIsAllowed = true;
  public formatOptions: NumberFormatOptions = {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol",
  };
  public showLoading = false;
  public errorMsg = "";

  public minimumStarsToVote = 3;

  public voteForm: FormGroup = new FormGroup({
    comment: new FormControl(this.voteObject.comment),
    tipAmount: new FormControl(this.voteObject.tipAmount),
    emailPaymentReceipt: new FormControl(this.voteObject.emailPaymentReceipt, Validators.email)
  });

  private _color = '8E8075'; //please don't add hash #

  constructor(private _guestService: GuestService, private _loginService: GuestLoginService, private _datePipe: DatePipe) {};

  ngOnInit(): void {
    this.dialogStarNotFoundActive = false;
    this.stars = new Array(5).fill(1).map((item, index) => item + index);
    //console.log('myVoteObject:', this.voteObject.type);
    const allDepartments = this._guestService.getDepartments();
    const allEmployee = this._guestService.getEmployees();
    if (this.voteObject.departmentId)  {
      this.departmentName = allDepartments.filter(a => a.id === this.voteObject.departmentId)[0].name;
    }
    if (this.voteObject.employeeId) {
      this.employeeName = allEmployee.filter(a => a.id === this.voteObject.employeeId)[0].firstName;
      this.employeePicturePath = allEmployee.filter(a => a.id === this.voteObject.employeeId)[0].thumbnailUrl;
      this.employeePictureName = allEmployee.filter(a => a.id === this.voteObject.employeeId)[0].id;
    }
    this.voteObject.comment = this.voteObject.comment ? this.voteObject.comment : '';
    this.commentCharachtersCount = this.voteObject.comment ? this.voteObject.comment.length : 0;
    this.commentCounter = `${this.commentCharachtersCount}/${this.commentMaxLength}`;

    //check if guest voted already the employee in the last 24 hours
    if (this.voteObject.type === 'employee') {
      // console.log('eccomi');
      // console.log('lastName', this.voteObject.lastName);
      // console.log('room', this.voteObject.room);
      // console.log('employeeId', this.voteObject.employeeId);
      this._guestService.ratingIsAllowed(this.voteObject.lastName, this.voteObject.room, this.voteObject.employeeId).subscribe(res => {
        if (res.status === 'not_allowed') {
          this.ratingIsAllowed = false;
        }
        console.log('this.ratingIsAllowed', this.ratingIsAllowed);
      });
    }

  }

  public ratingIcon(item: number): string {
    return item <= this.voteObject.voteStars
      ? "star-icon k-icon k-i-star yellow"
      : "star-icon k-icon k-i-star-outline";
  }

  public onRatingClick(item: number) {
    this.voteObject.voteStars = item;
    if (item <= this.minimumStarsToVote) {
      this.voteForm.controls['tipAmount'].setValue(0);
      this.voteObject.tipAmount = 0;
    }
  }

  public clearValue() {
    this.voteObject.comment = '';
    this.voteForm.controls['comment'].setValue('');
    this.commentCharachtersCount = 0;
    this.commentCounter = `${this.commentCharachtersCount}/${this.commentMaxLength}`;
  }

  public onCommentValueChange(ev: string): void {
    this.commentCharachtersCount = ev.length;
    this.commentCounter = `${this.commentCharachtersCount}/${this.commentMaxLength}`;
  }

  public closeDialogStarNotFound() {
    this.dialogStarNotFoundActive = false;
  }

  public doAction(action: string) {

    switch(action) {
      case 'vote':
        this.voteForm.markAllAsTouched();
        if (this.voteObject.voteStars >=1 ) {
            const myDate = new Date();
            this.voteObject.comment = this.voteForm.controls['comment'].value;
            this.voteObject.tipAmount = this.voteForm.controls['tipAmount'].value;
            this.voteObject.hasTip = this.voteObject.tipAmount >= 1;
            this.voteObject.emailPaymentReceipt = this.voteForm.controls['emailPaymentReceipt'].value;
            this.voteObject.voteAt = this._datePipe.transform(myDate, 'yyyy-MM-dd HH:mm');
            this.dialogStarNotFoundActive = false;
            this.showLoading = true;
            // call api for save vote
            this._guestService.saveVote(this.voteObject).subscribe(res => {
              if (res.status === 'not_allowed') {
                this.ratingIsAllowed = false;
                return;
              } else {
                this.voteObject.requestCode = res.requestCode;
                this.voteObject.id = res.id;
              }

            }).add(() => {
              if (this.voteObject.voteStars >= this.minimumStarsToVote && this.voteObject.tipAmount > 0  && this.voteObject.emailPaymentReceipt.length > 0 ) {
                this.dialogProcedeWithPaymentActive = true;
              } else {
                this.dialogProcedeWithoutPaymentActive = true;
              }
              this.showLoading = false;
            });
        } else {
          this.dialogStarNotFoundActive = true;
        }
        break;
      case 'closeDialogThanksWithoutPayment':
        this.dialogProcedeWithPaymentActive = false;
        this.dialogProcedeWithoutPaymentActive = false;
        this._loginService.logout();
        break;
      case 'closeDialogConfirmPayment':
        this.dialogProcedeWithPaymentActive = false;
        this.dialogProcedeWithoutPaymentActive = false;
        this._loginService.logout();
        break;
      case 'newVote':
        this.dialogProcedeWithPaymentActive = false;
        this.dialogProcedeWithoutPaymentActive = false;
        this.resetVote.emit();
        break;
      case 'procedeToPayment':
        this.dialogProcedeWithPaymentActive = false;
        this.dialogProcedeWithoutPaymentActive = false;
        //alert('WORK IN PROGRESS!! Process the payment!');
        //make payment
        this.showLoading = true;
        this.errorMsg = "";
        this._guestService.makePayment(this.voteObject, this._color).subscribe({
          next: res => {
            if (res) {
              localStorage.setItem('orderCode', res.orderCode);
              localStorage.setItem('voteObject', JSON.stringify(this.voteObject));
              window.open(res.link, "_self");
            }
          },
          error: err => this.errorMsg = 'An unexpected error occurs',
        }).add(() => this.showLoading = false);
        //this._loginService.logout();
        break;


    }


  }

}
