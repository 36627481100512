<div class="main-container">
  <div class="login-container">
    <div class="left-column">
      &nbsp;
    </div>
    <div class="logo">
      <img src="../../assets/media/images/logo_login.png" title="Cavallino Bianco" alt="Cavallino Bianco">
    </div>
    <div class="main-title">
      Rate & Reward<br />Admin Area
    </div>
    <div class="login-box">
      <div class="login-inner">
        <div class="login-title"><span class="icon k-icon k-i-login"></span>Admin Area Login</div>
        <div class="form-container">
          <form class="k-form"  #loginForm="ngForm" (ngSubmit)="onLoginFormSubmit()" [formGroup]="loginFormGroup">
            <fieldset class="k-form-fieldset">
              <div class="form-fields-container">
                <kendo-formfield>
                  <kendo-label [for]="username" text="Username"> </kendo-label>
                  <kendo-textbox
                    formControlName="username"
                    #username
                    required
                  ></kendo-textbox>
                  <kendo-formerror>Username is mandatory</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                  <kendo-label [for]="password" text="Password"></kendo-label>
                  <kendo-textbox
                    formControlName="password"
                    #password
                    required
                    type="password"
                  >
                    <ng-template kendoTextBoxSuffixTemplate>
                      <button type="button"
                        kendoButton
                        look="clear"
                        icon="eye"
                        (click)="toggleVisibility()"
                      ></button>
                    </ng-template>
                  </kendo-textbox>
                  <kendo-formerror>Password is mandatory</kendo-formerror>
                </kendo-formfield>
              </div>
              <div class="form-buttons">
                <div class="login-button-row">
                  <button kendoButton themeColor="primary" type="submit" class="button-primary">Login</button>&nbsp;&nbsp;
                  <button kendoButton themeColor="secondary" fillMode="outline" class="button-link" (click)="openRecoveryPage()">Forgot password</button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<kendo-dialog
  class="dialog-not-found"
  [title]="'Not found!'"
  *ngIf="userNotFoundActive"
  (close)="closeDialogUserNotFound()"
  [minWidth]="300"
  [width]="300"
>
  <p style="margin: 30px; text-align: center;">
    User not found or wrong password!<br />
    Please try to insert data again!
  </p>
  <kendo-dialog-actions>
    <button kendoButton class="close-button" themeColor="secondary" (click)="closeDialogUserNotFound()" >
      Try again
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

