import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit{
  constructor(private _router: Router) { }

  ngOnInit() {
    this._router.navigate(['guest/introduction']);
  }
}
