import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class AdminMainFooterComponent {
  public year = new Date().getFullYear();
  public currentLanguage: string  = 'de'

  constructor(private _translateService: TranslateService) { }

  ngOnInit(): void {
      this.currentLanguage = this._translateService.currentLang;
  }

}
