export class Vote {
  id: number;
  voteAt:string;
  room:string;
  lastName:string;
  language:string;
  type:string;
  employeeId:string;
  employeeFirstName:string;
  employeeLastName:string;
  departmentId:number;
  departmentName:string;
  employeeEmail:string;
  comment:string;
  voteStars:number;
  hasTip:boolean;
  tipAmount:number;
  emailPaymentReceipt:string;
  vivaWallerPaymentStatus:string;
  vivaWalletTransactionId:string;
  requestCode:string;
}
