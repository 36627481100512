import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { SharedService } from 'src/app/shared/shared.service';
import * as moment from 'moment';
import { AdminLoginService } from '../../login/admin-login.service';

@Component({
  selector: 'app-admin-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class AdminMainToolbarComponent {

  @Output() public toggle = new EventEmitter();
  @Input() public selectedPage?: string;

  public currentUser: User;
  public menuIconName = 'menu';
  public locale: string | null;


  @ViewChild("anchor") public anchor: ElementRef;
  @ViewChild("popup", { read: ElementRef }) public popup: ElementRef;

  constructor(private _loginService: AdminLoginService, public translateService: TranslateService, private _router: Router, private _sharedService: SharedService) { }

  ngOnInit(): void {
    const lsLoggedUser = localStorage.getItem('accountData');
    if (lsLoggedUser) {
      this.currentUser = JSON.parse(lsLoggedUser);
      if (localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'de';
      }
    } else {
      this._loginService.logout();
    }
  }

  onToggleSidenav() {
    this.toggle.emit();
    this.menuIconName = (this.menuIconName === 'menu') ? 'close' : 'menu';
  }

  doUserLogout() {
    this._loginService.logout().subscribe(result => {
      if (result.status === 'success') {
        this._router.navigate(['/admin/login']);
      }
    });
  }

  private _contains(target: EventTarget): boolean {
    if (this.anchor) {
      return (this.anchor.nativeElement.contains(target) || (this.popup ? this.popup.nativeElement.contains(target) : false)
      );
    } else {
      return false;
    }
  }

}
