<app-admin-main-toolbar (toggle)="toggleDrawer(drawer)" [selectedPage]="selected"></app-admin-main-toolbar>
<kendo-drawer-container>
  <kendo-drawer
      #drawer
      [items]="drawerItems"
      [mode]="mode"
      [mini]="mini"
      [miniWidth]="70"
      [width]="menuWidth"
      [animation]="true"
      [(expanded)]="drawerExpanded"
      [autoCollapse]="true"
      (select)="onSelect($event)"
      [classList]="'main-menu'"
  >
    <ng-template kendoDrawerItemTemplate let-item>
      <ng-container *ngIf="item.code !== '*menutitle*' && item.code !== '*menuimage*'" style="margin-bottom: 300px;">
        <div *ngIf="!item.hidden" class="divmenu divmenu-level-{{ item.level }} {{item.expanded?'expanded':''}} {{item.selected?'selected':''}}" (click)="goToPage(item)">
          <span class="k-icon div-icon {{ item.icon}}" ></span>
          <div class="menu-title">{{ item.name }}<span></span></div>
          <span
            *ngIf="(item.expanded) && item['children']"
            class="k-icon k-i-arrow-chevron-down expanded "
            style="margin-left: auto; display: none;"
          ></span>
          <span
            *ngIf="(!item.expanded) && item['children']"
            class="k-icon k-i-arrow-chevron-right arrow-left"
            style="margin-left: auto; display: none;"
          ></span>
        </div>
        <ng-container *ngFor="let child of item.children">
          <div *ngIf="!child.hidden" class="divmenu divmenu-level-{{ child.level }} {{child.selected?'selected':''}}" (click)="goToPage(child)">
            <div class="menu-title">{{ child.name }} <span></span></div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="item.code === '*menutitle*'">
          <div class="divmenu-title">
            {{ item.name }}
          </div>
      </ng-container>
      <ng-container *ngIf="item.code === '*menuimage*'">
        <div class="divmenu-image" style="position:absolute; bottom:0; left:0px; right:0px; z-index:-1">
          <img src="../../../assets/media/images/logo_login.png" title="Cavallino Bianco - Tips & Rate - Admin Area" alt="Cavallino Bianco - Tips & Rate - Admin Area"
            style="display: block;
              width: 60%;
              margin: 0 auto;
              height: auto;
              opacity: 0.35;"
            />
        </div>
    </ng-container>
    </ng-template>
  </kendo-drawer>
  <kendo-drawer-content>
    <router-outlet></router-outlet>
  </kendo-drawer-content>
</kendo-drawer-container>
<app-admin-main-footer></app-admin-main-footer>
