import { Component, Input, LOCALE_ID, ViewChild } from '@angular/core';
import { CellOptions, ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, MultipleSortSettings, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { VotesAndTipsListElement, VotesAndTipsListService } from './votes-and-tips-list.service';
import { AdminService } from '../../admin.service';
import { DatePipe, formatCurrency } from '@angular/common';
import { SVGIcon, infoCircleIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-votes-and-tips-list',
  templateUrl: './votes-and-tips-list.component.html',
  styleUrls: ['./votes-and-tips-list.component.scss']
})
export class VotesAndTipsListComponent {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public dateFromMax: Date = new Date();
  public dateToMax: Date = new Date();
  public selectedEmployeeId?: string;
  public selectedDepartmentId?: string;
  public selectedTypeId?: string;
  public guestName: string = '';
  public dateFrom : Date = new Date(new Date().setDate(new Date().getDate()-31)); //last 30 days
  public dateTo : Date = new Date();
  public isCashTip: boolean = false;
  public isVivaPayment: boolean = false;
  public isNoFilter: boolean = true;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public votesAndTipsArray: VotesAndTipsListElement[] = [];
  public pageCode: string;

  public expandedComments: Set<number> = new Set();
  public infoCircleIcon: SVGIcon = infoCircleIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'bottom';

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 10,
    responsive: false,
    pageSizes: [
      5,
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings: MultipleSortSettings = {
    allowUnsort: true,
  };

  public amountCellOptions: CellOptions = {
    format: '#,0.00',
  }

  public state: State = {
    skip: 0,
    take: this.pagingSettings.pageSize,
    sort: [
      { field: "ratingDate", dir: "desc" }
    ]
    // Initial filter descriptor
  };

  public employeesSource: Array<{ text: string; value: string }> = [];
  public employeesArray: Array<{ text: string; value: string }>;

  public departmentsSource: Array<{ text: string; value: string }> = [];
  public departmentsArray: Array<{ text: string; value: string }>;

  public typesSource: Array<{ text: string; value: string }> = [
    { text: 'Employee', value: 'employee' },
    { text: 'Department', value: 'department' },
    { text: 'Team Cavallino Bianco', value: 'company' },
    { text: 'End-of-Season Trip', value: 'trip' },
  ];
  public typesArray: Array<{ text: string; value: string }>;

  public loading = false;

  constructor(private _votesAndTipsListService: VotesAndTipsListService, private _adminService: AdminService, private _datepipe: DatePipe) {

  };

  public ngOnInit(): void {

    this._adminService.getDepartmentsFromApi().subscribe(result => {
      result.forEach(rec => {
        const elem  = { text: rec.name, value: rec.id.toString() };
        this.departmentsSource.push(elem);
        this.departmentsArray = this.departmentsSource;
      });
//      console.log('result', result);
    });

    this._adminService.getEmployeesFromApi().subscribe(result => {
      const sortedResult = result.sort((a, b) => a.firstName.localeCompare(b.firstName));
      sortedResult.forEach(rec => {
        const elem  = { text: rec.firstName + ' ' + rec.lastName, value: rec.id.toString() };
        this.employeesSource.push(elem);
        this.employeesArray = this.employeesSource;
      });
    });

    this.typesArray = this.typesSource;
    this._setGridData();

  }

  private _setGridData() {
    this.loading = true;
    const dateFrom = moment(this.dateFrom).format('YYYY-MM-DD');
    const dateTo = moment(this.dateTo).format('YYYY-MM-DD');
    const employeeId = this.selectedEmployeeId;
    const departmentId = this.selectedDepartmentId;
    const guestName = this.guestName;
    const type = this.selectedTypeId;

    this._votesAndTipsListService.getVotesAndTipsList(employeeId, departmentId, guestName, type, dateFrom, dateTo).subscribe(result => {
      if (result) {
        // console.log(result);

        result.forEach(item => {
          if (item.employeeLastName || item.employeeFirstName) {
            item.employeeFullName = item.employeeFirstName + ' ' + item.employeeLastName;
          } else {
            item.employeeFullName = '-';
          }
 /*         if (item.departmentName) {
            console.log('source', this.departmentsSource);
            console.log('item.departmentId:', item.departmentId);
            const departmentSource = this.departmentsSource.find(x => x.value == item.departmentId.toString());
            console.log('department source', departmentSource);
            item.departmentName = departmentSource.text;
          }*/
          if (item.room) {
            item.customerLastName = item.customerLastName + ' (' + item.room + ') ';
          }
          if (item.placementAt) {
            item.ratingDate = item.placementAt;
            item.ratingTime = item.placementAt;
          }
          item.hasComment = (item.comment !== '');
        });
        // console.log('myResult:', result);
        this.votesAndTipsArray = result;

        if (this.isCashTip) this._filterCashTip();
        if (this.isVivaPayment) this._filterVivaPayments();

        this.gridData = process(this.votesAndTipsArray, this.state);
        this.excelExportData = this.excelExportData.bind(this);
        this.loading = false;
      }

    });

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.gridData = process(this.votesAndTipsArray, this.state);
    setTimeout(() => {
      this.loading = false;
    }, 100);
  }

  public excelExportData(): ExcelExportData {
    const excelData = process(this.votesAndTipsArray, {
      sort: this.state.sort,
      filter: this.state.filter,

    });
    const result: ExcelExportData = {
      data: excelData.data
    };
    return result;
  }

  public applyFilter() {
    if (!this.dateFrom) this.dateFrom = new Date(new Date().setDate(new Date().getDate()-31)); //last 30 days
    if (!this.dateTo) this.dateTo = new Date(new Date().setDate(new Date().getDate()-1)); //yesterday
    this.state.skip = 0;
    this.state.take = this.pagingSettings.pageSize;
    this._setGridData();
  }

  public handleEmployeeFilter(value: any) {
    this.employeesArray = this.employeesSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleEmployeeSelection(data: any) {
    if (data) {
      this.selectedEmployeeId = data.value;
    } else {
      this.selectedEmployeeId = undefined;
    }
  }

  public handleDepartmentFilter(value: any) {
    this.departmentsArray = this.departmentsSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleDepartmentSelection(data: any) {
    if (data) {
      this.selectedDepartmentId = data.value;
    } else {
      this.selectedDepartmentId = undefined;
    }
  }

  public handleTypeFilter(value: any) {
    this.typesArray = this.typesSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleTypeSelection(data: any) {
    if (data) {
      this.selectedTypeId = data.value;
    } else {
      this.selectedTypeId = undefined;
    }
  }

  public handleNoFilter(event: Event) {
    const target = event.target as HTMLInputElement;
    this.isNoFilter = target.checked;
    if (this.isNoFilter) {
      if (this.guestName === 'tipcash') this.guestName = ''
      this.isCashTip = false;
      this.isVivaPayment = false;
      this.state.sort = [];
    }
  }

  public handleCashTipFilter(event: Event) {
    const target = event.target as HTMLInputElement;
    this.isCashTip = target.checked;
    if (this.isCashTip) {
      this.isVivaPayment = false;
      this.isNoFilter = false;
    }
  }

  private _filterCashTip() {
    this.guestName = 'tipcash';
    this.votesAndTipsArray = this.votesAndTipsArray.filter(item => {
      return item.room === '9999';
    });
  }

  public handleVivaPaymentFilter(event: Event) {
    const target = event.target as HTMLInputElement;
    this.isVivaPayment = target.checked;
    if (this.isVivaPayment) {
      if (this.guestName === 'tipcash') this.guestName = '';
      this.isCashTip = false;
      this.isNoFilter = false;
    }
  }

  private _filterVivaPayments() {
    this.votesAndTipsArray = this.votesAndTipsArray.filter(item => {
      return item.hasTip && item.room !== '9999';
    });
    this.state.sort = [
      {field: 'tipAmount', dir: 'asc'},
    ];
  }

  isCommentExpanded(dataItem: any): boolean {
    return this.expandedComments.has(dataItem.id);
  }

  toggleCommentExpansion(dataItem: any): void {
    const id = dataItem.id;
    if (this.expandedComments.has(id)) {
      this.expandedComments.delete(id);
    } else {
      this.expandedComments.add(id);
    }
  }

}
