import { Component, OnInit } from '@angular/core';
import { GuestLoginService } from '../../login/guest-login.service';

@Component({
  selector: 'app-vw-payment',
  templateUrl: './vw-payment.component.html',
  styleUrls: ['./vw-payment.component.scss']
})
export class VwPaymentComponent implements OnInit{

  constructor(private _loginService: GuestLoginService) {

  }

  ngOnInit(): void {
    if (!localStorage.getItem('isLogged')) {
      this._loginService.logout();
    }
  }


}
