import { Component, ViewChild } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, MultipleSortSettings, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { AdminService } from '../../admin.service';
import { DatePipe } from '@angular/common';
import { SVGIcon, infoCircleIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { StarsReportElement, StarsReportService } from './stars-report.service';

@Component({
  selector: 'app-stars-report',
  templateUrl: './stars-report.component.html',
  styleUrls: ['./stars-report.component.scss']
})
export class StarsReportComponent {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public dateFromMax: Date = new Date();
  public dateToMax: Date = new Date();
  public selectedEmployeeId?: string;
  public selectedDepartmentId?: string;
  public selectedTypeId?: string;
  public typeColumnLabel: string = 'employee';
  public guestName: string = '';
  public dateFrom : Date = new Date(new Date().setDate(new Date().getDate()-31)); //last 30 days
  public dateTo : Date = new Date();


  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public reportArray: StarsReportElement[] = [];
  public pageCode: string;

  public infoCircleIcon: SVGIcon = infoCircleIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'bottom';

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 10,
    responsive: false,
    pageSizes: [
      5,
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings: MultipleSortSettings = {
    allowUnsort: true,
  };

public state: State = {
  skip: 0,
  take: this.pagingSettings.pageSize,
  // Initial filter descriptor
};

  public employeesSource: Array<{ text: string; value: string }> = [];
  public employeesArray: Array<{ text: string; value: string }>;

  public departmentsSource: Array<{ text: string; value: string }> = [];
  public departmentsArray: Array<{ text: string; value: string }>;

  public typesSource: Array<{ text: string; value: string }> = [
    { text: 'Employee', value: 'employee' },
    { text: 'Department', value: 'department' },
    { text: 'Team Cavallino Bianco', value: 'company' },
    { text: 'End-of-Season Trip', value: 'trip' },
  ];
  public typesArray: Array<{ text: string; value: string }>;
  public type: { text: string; value: string } = { text: 'employee', value: 'employee' };

  public loading = false;

  public stars = [1,2,3,4,5];

  constructor(private _starsReportService: StarsReportService, private _adminService: AdminService, private _datepipe: DatePipe) {

    };

  public ngOnInit(): void {

    this._adminService.getDepartmentsFromApi().subscribe(result => {
      result.forEach(rec => {
        const elem  = { text: rec.name, value: rec.id.toString() };
        this.departmentsSource.push(elem);
        this.departmentsArray = this.departmentsSource;
      });
      console.log('result', result);
    });

    this._adminService.getEmployeesFromApi().subscribe(result => {
      const sortedResult = result.sort((a, b) => a.firstName.localeCompare(b.firstName));
      sortedResult.forEach(rec => {
        const elem  = { text: rec.firstName + ' ' + rec.lastName, value: rec.id.toString() };
        this.employeesSource.push(elem);
        this.employeesArray = this.employeesSource;
      });
    });

    this.typesArray = this.typesSource;
    this.selectedTypeId = 'employee';
    this.type = { text: 'employee', value: 'employee' };
  }

  private _setGridData() {
    this.loading = true;
    const dateFrom = moment(this.dateFrom).format('YYYY-MM-DD');
    const dateTo = moment(this.dateTo).format('YYYY-MM-DD');
    const employeeId = this.selectedEmployeeId;
    const departmentId = this.selectedDepartmentId;
    const guestName = this.guestName;
    const type = this.selectedTypeId;
    this.reportArray = [];
    //if (employeeId || departmentId) {
      if (type === 'employee') {
        this._adminService.getEmployeesFromApi().subscribe(result => {
          if (employeeId) {
            result = result.filter(r=> r.id === employeeId);
          }
          if (departmentId) {
            result = result.filter(r => r.departmentId.toString() === departmentId);
          }
          let index = 0;
          result.forEach(employee => {
            this._starsReportService.getStarsReport(employee.id, employee.departmentId.toString(), guestName, 'employee', dateFrom, dateTo).subscribe(resultS => {
              index ++;
              if (resultS) {
                const item: StarsReportElement = {
                  type : 'employee',
                  name : employee.firstName + ' ' + employee.lastName,
                  one : 0,
                  two : 0,
                  three : 0,
                  four : 0,
                  five : 0,
                  countRating : 0,
                  average: 0,
                  averageForExport: 0
                }
                resultS.forEach(element => {
                  item.one = item.one + element.one;
                  item.two = item.two + element.two;
                  item.three = item.three + element.three;
                  item.four = item.four + element.four;
                  item.five = item.five + element.five;
                  item.countRating = item.countRating + element.one + element.two + element.three + element.four + element.five;
                })
                item.average = parseFloat(((item.one * 1 + item.two * 2 + item.three * 3 + item.four * 4 + item.five * 5) / item.countRating).toFixed(2));
                item.averageForExport = (item.one * 1 + item.two * 2 + item.three * 3 + item.four * 4 + item.five * 5) / item.countRating;
                if (item.countRating > 0 ) {
                  this.reportArray.push(item);
                }
              }
              if (result.length === index) {
                this.gridData = process(this.reportArray, this.state);
                this.excelExportData = this.excelExportData.bind(this);
                this.loading = false;
              }
            });
          });
        });
      } else {
        if (type === 'department') {
          this._adminService.getDepartmentsFromApi().subscribe(result => {
            if (departmentId) {
              result = result.filter(r => r.id.toString() === departmentId);
            }
            let index = 0;
            result.forEach(department => {
              this._starsReportService.getStarsReport(null, department.id.toString(), guestName, '', dateFrom, dateTo).subscribe(resultS => {
                index ++;
                if (resultS) {
                  const item: StarsReportElement = {
                    type : 'department',
                    name : department.name,
                    one : 0,
                    two : 0,
                    three : 0,
                    four : 0,
                    five : 0,
                    countRating : 0,
                    average: 0,
                    averageForExport: 0
                  }
                  resultS.forEach(element => {
                    item.one = item.one + element.one;
                    item.two = item.two + element.two;
                    item.three = item.three + element.three;
                    item.four = item.four + element.four;
                    item.five = item.five + element.five;
                    item.countRating = item.countRating + element.one + element.two + element.three + element.four + element.five;
                  })
                  item.average = parseFloat(((item.one * 1 + item.two * 2 + item.three * 3 + item.four * 4 + item.five * 5) / item.countRating).toFixed(2));
                  item.averageForExport = (item.one * 1 + item.two * 2 + item.three * 3 + item.four * 4 + item.five * 5) / item.countRating;
                  if (item.countRating > 0 ) {
                    this.reportArray.push(item);
                  }
                }
                if (result.length === index) {
                  this.gridData = process(this.reportArray, this.state);
                  this.excelExportData = this.excelExportData.bind(this);
                  this.loading = false;
                }
              });
            });
          });
        }
      }
    //}
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.gridData = process(this.reportArray, this.state);
    setTimeout(() => {
      this.loading = false;
    }, 100);
  }

  public excelExportData(): ExcelExportData {
    const excelData = process(this.reportArray, {
      sort: this.state.sort,
      filter: this.state.filter,

    });
    const result: ExcelExportData = {
      data: excelData.data
    };
    return result;
  }

  public applyFilter() {
    if (!this.dateFrom) this.dateFrom = new Date(new Date().setDate(new Date().getDate()-31)); //last 30 days
    if (!this.dateTo) this.dateTo = new Date(new Date().setDate(new Date().getDate()-1)); //yesterday
    if (!this.selectedTypeId) {
      this.selectedTypeId = 'employee';
      this.type = { text: 'employee', value: 'employee' };
    }
    this.typeColumnLabel = this.type.text;
    this.state.skip = 0;
    this.state.take = this.pagingSettings.pageSize;
    this._setGridData();
  }

  public handleEmployeeFilter(value: any) {
    this.employeesArray = this.employeesSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleEmployeeSelection(data: any) {
    if (data) {
      this.selectedEmployeeId = data.value;
    } else {
      this.selectedEmployeeId = undefined;
    }
  }

  public handleDepartmentFilter(value: any) {
    this.departmentsArray = this.departmentsSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleDepartmentSelection(data: any) {
    if (data) {
      this.selectedDepartmentId = data.value;
    } else {
      this.selectedDepartmentId = undefined;
    }
  }

  public handleTypeFilter(value: any) {
    this.typesArray = this.typesSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleTypeSelection(data: any) {
    if (data) {
      this.selectedTypeId = data.value;
    } else {
      this.selectedTypeId = undefined;
    }
  }

}
