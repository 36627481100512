<div class="container">
  <div class="title">{{ 'start' | translate }}</div>
  <div class="content">
    <div class="start-msg">{{ (cashTipRegistration ? 'cash_tip_msg' : 'start_msg') | translate }}</div>
    <div class="form-container" fxLayout="row" fxLayoutAlign="center center">
      <form class="k-form" [formGroup]="form">
        <kendo-formfield orientation="horizontal" showHints="initial">
          <ul class="k-radio-list">
            <li class="k-radio-item">
              <input
                type="radio"
                #employee
                value="employee"
                kendoRadioButton
                formControlName="type"
              />
              <kendo-label [for]="employee" text="{{ 'employee' | translate }}"></kendo-label>
            </li>
            <li class="k-radio-item">
              <input
                type="radio"
                #department
                value="department"
                kendoRadioButton
                formControlName="type"
              />
              <kendo-label [for]="department" text="{{ 'department' | translate }}"></kendo-label>
            </li>
          </ul>
          <ul class="k-radio-list">
            <li class="k-radio-item">
              <input
                type="radio"
                #company
                value="company"
                kendoRadioButton
                formControlName="type"
              />
              <kendo-label [for]="company" text="{{ 'hotel_and_team' | translate }}"></kendo-label>
            </li>
            <li class="k-radio-item">
              <input
                type="radio"
                #trip
                value="trip"
                kendoRadioButton
                formControlName="type"
              />
              <kendo-label [for]="trip" text="{{ 'trip' | translate }}"></kendo-label>
            </li>
          </ul>
          <kendo-formerror>{{ 'start_err' | translate }}</kendo-formerror>
        </kendo-formfield>
        <div class="button-row">
          <button kendoButton class="button-primary" (click)="submitForm()">
            {{ 'proceed' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

