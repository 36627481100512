import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddEvent, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Employee } from 'src/app/shared/models/employee.model';
import { Vote } from 'src/app/shared/models/vote.model';
import { GuestService } from '../../guest.service';
import { Department } from 'src/app/shared/models/department.model';

@Component({
  selector: 'app-choose-department',
  templateUrl: './choose-department.component.html',
  styleUrls: ['./choose-department.component.scss']
})
export class ChooseDepartmentComponent {

  @Input() voteObject: Vote = new Vote;
  @Output() nextStepVote: EventEmitter<Vote> = new EventEmitter();
  public mySelection: string[] = [];
  public departmentDataArray: Department[] = [];

  public gridData: GridDataResult;

  public sortingSettings = {
    multiple: true,
    allowUnsort: true,
  };

  public state: State = {
    skip: 0,
    // Initial filter descriptor
  };


  constructor(private _guestService: GuestService) {
  }

  ngOnInit(): void {

    this._setGridData();

  }

  private _setGridData() {
    this.departmentDataArray = this._guestService.getDepartments();
    this.gridData = process(this.departmentDataArray, this.state);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.departmentDataArray, this.state);
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;
    if (inputValue) {
      this.state.filter =
        {
          logic: "or",
          filters: [
            {
              field: "name",
              operator: "contains",
              value: inputValue,
            }
          ],
      };
    } else {
      this.state.filter = undefined;
    }
    this.state.skip = 0;
    this.gridData = process(this.departmentDataArray, this.state);
  }

  public onStateChange(state: State): void {
    this.state = state;
    this._setGridData();
  }

  public selectDepartment(args: AddEvent): void {
    this.voteObject.employeeId = null;
    this.voteObject.employeeFirstName = null;
    this.voteObject.employeeLastName = null;
    this.voteObject.employeeEmail = null;
    this.voteObject.departmentId = args.dataItem.id;
    this.voteObject.departmentName = args.dataItem.name;
    this.nextStepVote.emit(this.voteObject);
  }

}
