import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GuestService } from 'src/app/guest/guest.service';
import { GuestLoginService } from 'src/app/guest/login/guest-login.service';
import { Vote } from 'src/app/shared/models/vote.model';
import { IntlService } from "@progress/kendo-angular-intl";


@Component({
  selector: 'app-vw-result-ok',
  templateUrl: './vw-result-ok.component.html',
  styleUrls: ['./vw-result-ok.component.scss']
})
export class VwResultOkComponent implements OnInit{

  constructor(private _router: Router, private _loginService: GuestLoginService, private _guestService: GuestService,
    private _route: ActivatedRoute, public intlService: IntlService ) {}

  public showSuccessPage = false;
  public showVerifyErrorPage = false;
  public voteObject: Vote;

  ngOnInit(): void {
    if (!localStorage.getItem('isLogged')) {
      this._loginService.logout();
    } else {
      this._route.queryParams
      .subscribe(params => {
        this.voteObject = JSON.parse(localStorage.getItem('voteObject'));
        this._guestService.verifyPayment(params['t'], params['s']).subscribe(res => {
          if (res.status === 'success') {
            this.showSuccessPage = true;
            this.showVerifyErrorPage = false;
          } else {
            this.showSuccessPage = false;
            this.showVerifyErrorPage = true;
          }
        },(error => {
          this.showSuccessPage = false;
          this.showVerifyErrorPage = true;
        }));
      });
    }
  }

  goHomeHandler() {
    this._router.navigate(['guest/main']);
  }

  public doAction(action: string) {
    switch(action) {
      case 'closePage':
        this._loginService.logout();
        break;
      case 'newVote':
        this.goHomeHandler();
        break;
    }
  }

}
