import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { TypographyModule } from '@progress/kendo-angular-typography';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ADMIN_ROUTES } from './admin.routes';
import { RouterModule } from '@angular/router';
import { AdminLoginComponent } from './login/admin-login.component';
import { AdminComponent } from './admin.component';
import { JWT_OPTIONS, JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { KendoMessagesService } from '../shared/kendo-messages.service';
import { MessageService } from '@progress/kendo-angular-l10n';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LOCAL_STORAGE } from '../shared/constants';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AdminMainComponent } from './main/main.component';
import { AdminMainToolbarComponent } from './main/main-toolbar/main-toolbar.component';
import { AdminMainFooterComponent } from './main/main-footer/main-footer.component';
import { MaterialDesignModule } from './material-design.module';
import { VotesAndTipsListComponent } from './modules/votes-and-tips-list/votes-and-tips-list.component';
import { ReportComponent } from './modules/report/report.component';
import { StarsReportComponent } from './modules/stars-report/stars-report.component';
import { DatePipe } from '@angular/common';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

export function tokenGetter() {
  return localStorage.getItem(LOCAL_STORAGE.CAVALLINO_TOKEN);
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    RouterModule.forChild(ADMIN_ROUTES),
    BrowserModule,
    HttpClientModule,
    GridModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    IndicatorsModule,
    TypographyModule,
    InputsModule,
    LabelModule,
    FlexLayoutModule,
    ButtonsModule,
    DialogsModule,
    NotificationModule,
    LayoutModule,
    DropDownsModule,
    TooltipsModule,
    DateInputsModule,
    PopupModule,
    MaterialDesignModule,
    ExcelModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AdminLoginComponent,
    AdminComponent,
    RecoverPasswordComponent,
    ResetPasswordComponent,
    AdminMainComponent,
    AdminMainToolbarComponent,
    AdminMainFooterComponent,
    VotesAndTipsListComponent,
    ReportComponent,
    StarsReportComponent
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: MessageService, useClass: KendoMessagesService },
    { provide: LOCALE_ID, useValue: 'en-US' },
    DatePipe
]
})
export class AdminModule { }
