<div class="main-container">
  <div class="login-container">
    <div class="left-column">
      &nbsp;
    </div>
    <div class="logo">
      <img src="../../assets/media/images/logo_login.png" title="Cavallino Bianco" alt="Cavallino Bianco">
    </div>
    <div class="main-title">
      Rate<br>&<br>Reward
    </div>
    <div class="login-box">
      <div class="login-inner">
        <div class="form-container">
          <form class="k-form"  #loginForm="ngForm" (ngSubmit)="onLoginFormSubmit()" [formGroup]="loginFormGroup">
            <fieldset class="k-form-fieldset">
              <div class="form-fields-container">
                <kendo-formfield>
                  <kendo-label [for]="lastname" text="{{ 'last_name' | translate }}"></kendo-label>
                  <kendo-textbox
                    formControlName="lastname"
                    #lastname
                    required
                    [clearButton]="true"
                  ></kendo-textbox>
                  <kendo-formerror>{{ 'last_name_mandatory' | translate }}</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                  <kendo-label [for]="room" text="{{ 'room_number' | translate }}"></kendo-label>
                  <kendo-textbox
                    formControlName="room"
                    #room
                    required
                    [clearButton]="true"
                  ></kendo-textbox>
                  <kendo-formerror>{{ 'room_mandatory' | translate }}</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                  <kendo-label
                  [for]="acceptPrivacy"
                  text="{{ 'privacy_accept' | translate }}"
                  ></kendo-label>
                  <input
                    formControlName="acceptPrivacy"
                    type="checkbox"
                    class="checkbox"
                    kendoCheckBox
                    #acceptPrivacy
                  />
                  <span [innerHTML]="privacyHTMLMessage" style="margin-left: 10px"></span>
                  <kendo-formerror>{{ 'privacy_mandatory' | translate }}</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                  <kendo-label
                  [for]="acceptTerms"
                  text="{{ 'terms_conditions_accept' | translate }}"
                  ></kendo-label>
                  <input
                    formControlName="acceptTerms"
                    type="checkbox"
                    class="checkbox"
                    kendoCheckBox
                    #acceptTerms
                  />
                  <span [innerHTML]="termsHTMLMessage" style="margin-left: 10px"></span>
                  <kendo-formerror>{{ 'terms_conditions_mandatory' | translate }}</kendo-formerror>
                </kendo-formfield>
              </div>
              <div class="form-buttons">
                <div class="login-button-row">
                  <button kendoButton themeColor="primary" type="submit" class="button-primary">{{ 'proceed' | translate }}</button>&nbsp;&nbsp;
                  <button kendoButton themeColor="secondary" fillMode="outline" class="button-link" (click)="openIntroductionPage()">{{ 'introduction' | translate }}</button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
    <div class="footer-container">
      <div kendoTypography variant="p" fontSize="sm" textAlign="center" class="poweredby-footer">
        <span>Powered by</span><span><b>Cavallino Bianco Family Spa Grand Hotel</b></span>
        <span><a href="mailto:ralph@cavallino-bianco.com" target="_blank">Email</a></span>
        <span><a href="//www.cavallino-bianco.com" target="_blank">Website</a></span>
        <span><b>IT00633280219</b></span>
      </div>
    </div>
  </div>
</div>
<kendo-dialog
  class="dialog-not-found"
  *ngIf="dialogGuestNotFoundActive"
  (close)="closeDialogGuestNotFound()"
  [minWidth]="300"
  [width]="300"
>
  <kendo-dialog-titlebar>
    {{ 'not_found' | translate }}
  </kendo-dialog-titlebar>
  <p style="margin: 30px; text-align: center;">
    {{ 'guest_room_404' | translate }}<br/>
    {{ 'data_again' | translate }}
  </p>
  <kendo-dialog-actions>
    <button kendoButton class="button-primary" (click)="closeDialogGuestNotFound()" >
      {{ 'try_again' | translate }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

