<div class="container">
  <div class="title">{{ 'choose_employee' | translate | uppercase }}</div>
  <div class="content">
    <div class="search-bar row" style="display:block; width:100%;">
      <div class="search-item col-6" style="padding: 2px;">
        <kendo-combobox
          [data]="departmentArray"
          textField="name"
          valueField="id"
          placeholder="{{ 'department' | translate}}"
          #department
          (filterChange)="onFilterDepartment($event)"
          (selectionChange)="onSelectDepartment($event)"
          [(ngModel)]="selectedDepartment"
          [filterable]="true"
          ></kendo-combobox>
      </div>
      <div class="search-item col-6" style="padding: 2px;">
        <input
          placeholder="{{ 'employee' | translate }}"
          kendoTextBox
          (input)="onFilterEmployee($event)"
        />
      </div>
    </div>
    <div class="data-box-container row" style="margin-top:20px;">
      <ng-container *ngIf="departmentFilterActive || employeeFilterActive;else defaultTemplate">
        <ng-container *ngFor="let item of gridData.data; let i = index">
          <div class="col-4 col-sm-6 data-box" style="padding:3px;">
            <div class="data-inner" style="border: 1px solid rgba(0, 0, 0, 0.12); padding:4px; border-radius: 5px;">
              <div class="name">{{item.firstName}}</div>
              <div class="data-wrapper" style="padding: 10px 0">
                  <div class="data-row">{{item.departmentName}}</div>
                  <div class="data-row image-row" style="margin:10px 0;">
                    <img *ngIf="item.thumbnailUrl; else noImage" src="{{item.thumbnailUrl}}" title="{{item.id}}" style="border-radius: 5px;">
                    <ng-template #noImage>
                      <img [width]="80" height="80px" src="assets/media/images/user_placeholder.png" title="no-picture" style="border-radius: 5px">
                    </ng-template>
                  </div>

                  <div class="data-row button-row" style="margin:10px 0;">
                    <kendo-button icon="hand" size="small" fillMode="solid" class="button-primary" (click)="selectEmployee(item)">{{ 'select' | translate }}</kendo-button>
                  </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #defaultTemplate>
        <div class="subtitle">{{ 'please_choose_e_d' | translate }}</div>
      </ng-template>
    </div>
  </div>
</div>

