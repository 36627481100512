import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, throwError } from "rxjs";
import { HttpUtils } from "src/app/shared/http-utils";
import { environment } from "src/environments/environment";

// class for returning object
export class VotesAndTipsListElement {
  id: number;
  customerLastName:string;
  customerEmail:string;
  room:string;
  customerLanguage:string;
  ratingType:string;
  employeeId:string;
  employeeFirstName:string;
  employeeLastName:string;
  employeeFullName:string;
  employeeEmail:string;
  departmentId:number;
  departmentName:string;
  ratingStar:number;
  ratingDate:Date;
  ratingTime:Date;
  comment:string;
  hasComment: boolean;
  hasTip:boolean;
  tipAmount:number;
  tipAmountFormatted:string;
  placementAt:Date;
  createdAt:string;
  paymentAt:string;
  vivaPaymentReceipt:string;
  vivaPaymentStatus:string;
  vivaPaymentDescription: string;
  vivaTransactionId: string;
  failedPayment: boolean;
}

enum EndpointRoutes {
  GET_RATINGS = '/ratings/get',
}

@Injectable({
  providedIn: 'root'
})
export class VotesAndTipsListService {

  constructor(private _http: HttpClient) { }

  public getVotesAndTipsList(pEmployeeId: string, pDepartmentId: string, pGuestName: string, pTypeId: string, pDateFrom: string, pDateTo: string): Observable<VotesAndTipsListElement[]> {
    const params:any = {
    };
    if (pEmployeeId) params.employeeId = pEmployeeId;
    if (pDepartmentId) params.departmentId = pDepartmentId;
    if (pGuestName) params.name = pGuestName;
    if (pTypeId) params.type = pTypeId;
    if (pDateFrom) params.from = pDateFrom;
    if (pDateTo) params.to = pDateTo;
    return this._http.get<VotesAndTipsListElement[]>(environment.serverUrlAdmin + EndpointRoutes.GET_RATINGS, { headers: HttpUtils.createHeaders(), params}).pipe(
      map(responseData => {
        responseData.forEach((item) => {
          item.failedPayment = false;
          if (!(item.room == "9999" && item.customerLastName.toLowerCase() == "tipcash")) {
            item.failedPayment = item.hasTip && !item.vivaTransactionId;
            item.tipAmount = item.failedPayment ? 0 : item.tipAmount;
          }
        });
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }


}
