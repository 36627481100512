import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpUtils } from 'src/app/shared/http-utils';
import { AdminUserLocalStorageObject, LocalStorageService} from 'src/app/shared/local-storage.service';
import { User } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';


export class LoginReturnError {
  stringCode!: string;
  value!: string | { waitTime: string };
}

enum EndpointRoutes {
  ACCOUNT_LOGIN = '/account/login',
  ACCOUNT_LOGOUT = '/account/logout',
}

@Injectable({
  providedIn: 'root'
})

export class AdminLoginService {

  userData = new BehaviorSubject<AdminUserLocalStorageObject|null>(null);

  constructor(private _http: HttpClient, private _router: Router, private _localStorage: LocalStorageService) { }

  // properties
  public get currentUserDataValue(): AdminUserLocalStorageObject | null {
    const userData: AdminUserLocalStorageObject = this._localStorage.getAdminUserLocalStorageObject();
    return userData;
  }

  // methods
  loginPost(username: string, password: string) {
    return this._http.post<User>(environment.serverUrlAdmin + EndpointRoutes.ACCOUNT_LOGIN, { username, password})
      .pipe(
        map(resData => {
          localStorage.clear();
          const userData = new AdminUserLocalStorageObject(resData.id, resData.sessionToken, resData.language);
          this.userData.next(userData);
        }),
        catchError(this._handleError)
      );
  }

  resetPassword(username: string) {

  }

  verifyCode(recoveryCode: string) {

  }

  createPassword(recoveryCode: string, password: string) {

  }

  validateRecoveryCode(recoveryCode: string) {

  }

  autoLogin() {
    const userData: AdminUserLocalStorageObject = JSON.parse(localStorage.getItem('userData') || '{}');
    // console.log('autoLogin_userData:', userData);
    if (!userData) {
      return;
    }
    const loadedUser = new AdminUserLocalStorageObject(userData.id, userData.sessionToken, userData.locale);
    if (loadedUser.sessionToken) {
      this.userData.next(userData);
      this._router.navigate(['/admin/main']);
    }
  }

  logout(): Observable<any> {
    return this._http.post<any>(environment.serverUrlAdmin + EndpointRoutes.ACCOUNT_LOGOUT, {}, { headers: HttpUtils.createHeaders() })
      .pipe(
        map(resData => {
          if (resData.status === 'success') {
            this.userData.next(null);
            localStorage.clear();
          }
          return resData;
        }),
        catchError(errorRes => {
          return throwError(() => errorRes);
        })
      );
  }

  private _handleError(reponseError: HttpErrorResponse): Observable<LoginReturnError> {
    let errorCode: LoginReturnError;
    switch (reponseError.status) {
      case 610:
        errorCode = {stringCode: 'wrongCredentials', value: reponseError.error};
        break;
      case 611:
        errorCode = {stringCode: 'wrongCredentials', value: reponseError.error};
        break;
      case 612:
        errorCode = {stringCode: 'accountDisabled', value: reponseError.error};
        break;
      case 613:
        errorCode = {stringCode: 'accountNotEnabled', value: reponseError.error};
        break;
      default:
        errorCode = {stringCode: 'wrongCredentials', value: reponseError.error};
        break;
    }

    return throwError(errorCode);
  }
}
