import { Injectable } from '@angular/core';
import { MessageService } from '@progress/kendo-angular-l10n';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class KendoMessagesService extends MessageService {


  private _data: any = {
    it: {
      rtl: false,
      messages: KendoAllTexts.getTranslationIT(),
    },
    de: {
      rtl: false,
      messages: KendoAllTexts.getTranslationDE(),
    },
    en: {
      rtl: false,
      messages: KendoAllTexts.getTranslationEN(),
    },
  };

  constructor (public _http: HttpClient) {
    super();
  }



  public set language(value: string) {
    const lang = this._data[value];
    if (lang) {
      this.localeId = value;
      this.notify(lang.rtl);
    }
  }

  public get language(): string {
    return this.localeId;
  }

  private localeId = "it";
  private get messages(): string | void {
    const lang = this._data[this.localeId];

    if (lang) {
      return lang.messages;
    }
  }

  public override get(key: string): string {
    return this.messages[key as keyof typeof this.messages];
  }

}


export abstract class KendoAllTexts {

  public static getTranslationIT() :any {
    const messages = {
      "kendo.grid.groupPanelEmpty": "Trascina l'header di una colonna e rilascialo qui per raggruppare secondo tale colonna",
      "kendo.grid.noRecords": "Nessun record disponibile.",
      "kendo.grid.pagerFirstPage": "Vai alla prima pagina",
      "kendo.grid.pagerPreviousPage": "Vai alla pagina precedente",
      "kendo.grid.pagerNextPage": "Vai alla prossima pagina",
      "kendo.grid.pagerLastPage": "Vai all'ultima pagina",
      "kendo.grid.pagerPage": "Pagina",
      "kendo.grid.pagerOf": "di",
      "kendo.grid.pagerItems": "elementi",
      "kendo.grid.pagerItemsPerPage": "elementi per pagina",
      "kendo.grid.pagerPageNumberInputTitle": "Numero pagina",
      "kendo.grid.filter": "Filtro",
      "kendo.grid.filterEqOperator": "È uguale a",
      "kendo.grid.filterNotEqOperator": "Non è uguale a",
      "kendo.grid.filterIsNullOperator": "È zero",
      "kendo.grid.filterIsNotNullOperator": "Non è nullo",
      "kendo.grid.filterIsEmptyOperator": "È vuoto",
      "kendo.grid.filterIsNotEmptyOperator": "Non è vuoto",
      "kendo.grid.filterStartsWithOperator": "Inizia con",
      "kendo.grid.filterContainsOperator": "Contiene",
      "kendo.grid.filterNotContainsOperator": "Non contiene",
      "kendo.grid.ilterEndsWithOperator": "Finisce con",
      "kendo.grid.filterGteOperator": "È maggiore o uguale a",
      "kendo.grid.filterGtOperator": "È maggiore di",
      "kendo.grid.filterLteOperator": "È minore o uguale a",
      "kendo.grid.filterLtOperator": "È minore di",
      "kendo.grid.filterIsTrue": "È vero",
      "kendo.grid.filterIsFalse": "È falso",
      "kendo.grid.filterBooleanAll": "(Tutti)",
      "kendo.grid.filterAfterOrEqualOperator": "È dopo o uguale a",
      "kendo.grid.filterAfterOperator": "È dopo",
      "kendo.grid.filterBeforeOperator": "È prima",
      "kendo.grid.filterBeforeOrEqualOperator": "È prima o uguale a",
      "kendo.grid.filterFilterButton": "Filtro",
      "kendo.grid.filterClearButton": "Rimuovi",
      "kendo.grid.filterAndLogic": "E",
      "kendo.grid.filterOrLogic": "O",
      "kendo.grid.filterDateToggle": "Mostra calendario",
      "kendo.grid.filterDateToday": "Oggi",
      "kendo.grid.filterNumericDecrement": "Decrementa",
      "kendo.grid.filterNumericIncrement": "Aumenta",
      "kendo.grid.loading": "Caricamento",
      "kendo.grid.columns": "Colonne",
      "kendo.grid.lock": "Blocca",
      "kendo.grid.unlock": "Sblocca",
      "kendo.grid.stick": "Attacca",
      "kendo.grid.unstick": "Stacca",
      "kendo.grid.setColumnPosition": "Applica posizione colonna",
      "kendo.grid.sortAscending": "Ordina Ascendente",
      "kendo.grid.sortDescending": "Ordina Discendente",
      "kendo.grid.columnsApply": "Applica",
      "kendo.grid.columnsReset": "Reset",
      "kendo.grid.sortable": "Ordinabile",
      "kendo.grid.sortedAscending": "Ordinato ascendente",
      "kendo.grid.sortedDescending": "Ordinato discendente",
      "kendo.grid.sortedDefault": "Non ordinato",
      "kendo.grid.filterInputLabel": "Filtro {columnName}",
      "kendo.grid.filterMenuTitle": "Menù filtro {columnName}",
      "kendo.grid.filterMenuOperatorsDropDownLabel": "Operatori filtro {columnName}",
      "kendo.grid.filterMenuLogicDropDownLabel": "{columnName} Locica filtro",
      "kendo.grid.columnMenu": "Menù colonna {columnName}",
      "kendo.grid.selectionCheckboxLabel": "Seleziona la riga",
      "kendo.grid.selectAllCheckboxLabel": "Seleziona tutte le righe",
      "kendo.grid.pagerLabel": "Paginazione, pagina {currentPage} di {totalPages}",
      "kendo.grid.gridLabel": "Tabella dati",
      "kendo.grid.groupCollapse": "Riduci il gruppo",
      "kendo.grid.groupExpand": "Espandi il gruppo",
      "kendo.grid.detailCollapse": "Riduci i dettagli",
      "kendo.grid.detailExpand": "Espandi i dettagli",
      "kendo.grid.autosizeThisColumn": "Ridimensiona automaticamente la colonna",
      "kendo.grid.autosizeAllColumns": "Ridimensiona automaticamente tutte le colonne",
      "kendo.grid.selectPage": "Seleziona pagina"
    }
    return messages;
  }

  public static getTranslationDE() :any {
    const messages = {
      "kendo.grid.groupPanelEmpty": "Ziehen Sie eine Spaltenüberschrift hierher, um nach dieser Spalte zu gruppieren",
      "kendo.grid.noRecords": "Keine Datensätze verfügbar.",
      "kendo.grid.pagerFirstPage": "Zur ersten Seite",
      "kendo.grid.pagerPreviousPage": "Zur vorherigen Seite",
      "kendo.grid.pagerNextPage": "Zur nächsten Seite",
      "kendo.grid.pagerLastPage": "Zur letzten Seite",
      "kendo.grid.pagerPage": "Seite",
      "kendo.grid.pagerOf": "von",
      "kendo.grid.pagerItems": "Elemente",
      "kendo.grid.pagerItemsPerPage": "Elemente pro Seite",
      "pagerPageNumberInputTitle": "Seite",
      "kendo.grid.filter": "Filter",
      "kendo.grid.filterEqOperator": "Ist gleich",
      "kendo.grid.filterNotEqOperator": "Ist nicht gleich",
      "kendo.grid.filterIsNullOperator": "Ist Null",
      "kendo.grid.filterIsNotNullOperator": "Ist nicht Null",
      "kendo.grid.filterIsEmptyOperator": "Ist leer",
      "kendo.grid.filterIsNotEmptyOperator": "Ist nicht leer",
      "kendo.grid.filterStartsWithOperator": "beginnt mit",
      "kendo.grid.filterContainsOperator": "beinhaltet",
      "kendo.grid.filterNotContainsOperator": "beinhaltet nicht",
      "kendo.grid.filterEndsWithOperator": "endet mit",
      "kendo.grid.filterGteOperator": "Ist größer als oder gleich",
      "kendo.grid.filterGtOperator": "Ist größer als",
      "kendo.grid.filterLteOperator": "Ist kleiner oder gleich als",
      "kendo.grid.filterLtOperator": "Ist kleiner als",
      "kendo.grid.filterIsTrue": "Ist richtig",
      "kendo.grid.filterIsFalse": "Ist falsch",
      "kendo.grid.filterBooleanAll": "(Alle)",
      "kendo.grid.filterAfterOrEqualOperator": "Ist gleich oder später als",
      "kendo.grid.filterAfterOperator": "Ist später als",
      "kendo.grid.filterBeforeOperator": "Ist früher als",
      "kendo.grid.filterBeforeOrEqualOperator": "Ist gleich oder früher als",
      "kendo.grid.filterFilterButton": "Filtern",
      "kendo.grid.filterClearButton": "Löschen",
      "kendo.grid.filterAndLogic": "und",
      "kendo.grid.filterOrLogic": "oder",
      "kendo.grid.filterDateToggle": "Kalender umschalten",
      "kendo.grid.filterDateToday": "Heute",
      "kendo.grid.filterNumericDecrement": "Wert verringern",
      "kendo.grid.filterNumericIncrement": "Wert erhöhen",
      "kendo.grid.loading": "Laden",
      "kendo.grid.columns": "Spalten",
      "kendo.grid.lock": "Sperren",
      "kendo.grid.unlock": "Entsperren",
      "kendo.grid.stick": "Stick",
      "kendo.grid.unstick": "Unstick",
      "kendo.grid.setColumnPosition": "Spaltenposition festlegen",
      "kendo.grid.sortAscending": "Aufsteigend sortieren",
      "kendo.grid.sortDescending": "Absteigend sortieren",
      "kendo.grid.columnsApply": "Übernehmen",
      "kendo.grid.columnsReset": "Zurücksetzen",
      "kendo.grid.sortable": "Sortierbar",
      "kendo.grid.sortedAscending": "Aufsteigend sortiert",
      "kendo.grid.sortedDescending": "Absteigend sortiert",
      "kendo.grid.sortedDefault": "Nicht sortiert",
      "kendo.grid.filterInputLabel": "{columnName} Filter",
      "kendo.grid.filterMenuTitle": "{columnName} Filter Menü",
      "kendo.grid.filterMenuOperatorsDropDownLabel": "{columnName} Filter-Operatoren",
      "kendo.grid.filterMenuLogicDropDownLabel": "{columnName} Filter Logik",
      "kendo.grid.columnMenu": "{columnName} Spalte Menü",
      "kendo.grid.selectionCheckboxLabel": "Zeile auswählen",
      "kendo.grid.selectAllCheckboxLabel": "Alle Zeilen auswählen",
      "kendo.grid.pagerLabel": "Seitennavigation, Seite {currentPage} von {totalPages}",
      "kendo.grid.gridLabel": "Daten-Tabelle",
      "kendo.grid.groupCollapse": "Gruppe zusammenklappen",
      "kendo.grid.groupExpand": "Gruppe expandieren",
      "kendo.grid.detailCollapse": "Details zusammenklappen",
      "kendo.grid.detailExpand": "Details expandieren",
      "kendo.grid.autosizeThisColumn": "Diese Spalte automatisch vergrößern",
      "kendo.grid.autosizeAllColumns": "Alle Spalten automatisch vergrößern",
      "kendo.grid.selectPage": "Seite wählen"
    }
    return messages;
  }

  public static  getTranslationEN() :any {
    const messages = {
      "kendo.grid.groupPanelEmpty": "Drag a column header and drop it here to group by that column",
      "kendo.grid.noRecords": "No records available.",
      "kendo.grid.pagerFirstPage": "Go to the first page",
      "kendo.grid.pagerPreviousPage": "Go to the previous page",
      "kendo.grid.pagerNextPage": "Go to the next page",
      "kendo.grid.pagerLastPage": "Go to the last page",
      "kendo.grid.pagerPage": "Page",
      "kendo.grid.pagerOf": "of",
      "kendo.grid.pagerItems": "items",
      "kendo.grid.pagerItemsPerPage": "items per page",
      "kendo.grid.pagerPageNumberInputTitle": "Page Number",
      "kendo.grid.filter": "Filter",
      "kendo.grid.filterEqOperator": "Is equal to",
      "kendo.grid.filterNotEqOperator": "Is not equal to",
      "kendo.grid.filterIsNullOperator": "Is null",
      "kendo.grid.filterIsNotNullOperator": "Is not null",
      "kendo.grid.filterIsEmptyOperator": "Is empty",
      "kendo.grid.filterIsNotEmptyOperator": "Is not empty",
      "kendo.grid.filterStartsWithOperator": "Starts with",
      "kendo.grid.filterContainsOperator": "Contains",
      "kendo.grid.filterNotContainsOperator": "Does not contain",
      "kendo.grid.filterEndsWithOperator": "Ends with",
      "kendo.grid.filterGteOperator": "Is greater than or equal to",
      "kendo.grid.filterGtOperator": "Is greater than",
      "kendo.grid.filterLteOperator": "Is less than or equal to",
      "kendo.grid.filterLtOperator": "Is less than",
      "kendo.grid.filterIsTrue": "is true",
      "kendo.grid.filterIsFalse": "is false",
      "kendo.grid.filterBooleanAll": "(All)",
      "kendo.grid.filterAfterOrEqualOperator": "Is after or equal to",
      "kendo.grid.filterAfterOperator": "Is after",
      "kendo.grid.filterBeforeOperator": "Is before",
      "kendo.grid.filterBeforeOrEqualOperator": "Is before or equal to",
      "kendo.grid.filterFilterButton": "Filter",
      "kendo.grid.filterClearButton": "Clear",
      "kendo.grid.filterAndLogic": "And",
      "kendo.grid.filterOrLogic": "Or",
      "kendo.grid.filterDateToggle": "Toggle calendar",
      "kendo.grid.filterDateToday": "Today",
      "kendo.grid.filterNumericDecrement": "Decrease value",
      "kendo.grid.filterNumericIncrement": "Increase value",
      "kendo.grid.loading": "Loading",
      "kendo.grid.columns": "Columns",
      "kendo.grid.lock": "Lock",
      "kendo.grid.unlock": "Unlock",
      "kendo.grid.stick": "Stick",
      "kendo.grid.unstick": "Unstick",
      "kendo.grid.setColumnPosition": "Set Column Position",
      "kendo.grid.sortAscending": "Sort Ascending",
      "kendo.grid.sortDescending": "Sort Descending",
      "kendo.grid.columnsApply": "Apply",
      "kendo.grid.columnsReset": "Reset",
      "kendo.grid.sortable": "Sortable",
      "kendo.grid.sortedAscending": "Sorted ascending",
      "kendo.grid.sortedDescending": "Sorted descending",
      "kendo.grid.sortedDefault": "Not sorted",
      "kendo.grid.filterInputLabel": "{columnName} Filter",
      "kendo.grid.filterMenuTitle": "{columnName} Filter Menu",
      "kendo.grid.filterMenuOperatorsDropDownLabel": "{columnName} Filter Operators",
      "kendo.grid.filterMenuLogicDropDownLabel": "{columnName} Filter Logic",
      "kendo.grid.columnMenu": "{columnName} Column Menu",
      "kendo.grid.selectionCheckboxLabel": "Select Row",
      "kendo.grid.selectAllCheckboxLabel": "Select All Rows",
      "kendo.grid.pagerLabel": "Page navigation, page {currentPage} of {totalPages}",
      "kendo.grid.gridLabel": "Data table",
      "kendo.grid.groupCollapse": "Collapse Group",
      "kendo.grid.groupExpand": "Expand Group",
      "kendo.grid.detailCollapse": "Collapse Details",
      "kendo.grid.detailExpand": "Expand Details",
      "kendo.grid.autosizeThisColumn": "Autosize This Column",
      "kendo.grid.autosizeAllColumns": "Autosize All Columns",
      "kendo.grid.selectPage": "Select page"
    }
    return messages;
  }
}
