import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, throwError } from "rxjs";
import { HttpUtils } from "src/app/shared/http-utils";
import { environment } from "src/environments/environment";

// class for returning object
export class StarsReportElement {
  type:string;
  name:string;
  one:number;
  two:number;
  three:number;
  four:number;
  five:number;
  countRating:number;
  average:number;
  averageForExport:number;
}

enum EndpointRoutes {
  GET_STAR_REPORT = '/star/get',
}

@Injectable({
  providedIn: 'root'
})
export class StarsReportService {

  constructor(private _http: HttpClient) { }

  public getStarsReport(pEmployeeId: string, pDepartmentId: string, pGuestName: string, pTypeId: string, pDateFrom: string, pDateTo: string): Observable<StarsReportElement[]> {
    const params:any = {
    };
    if (pEmployeeId) params.employeeId = pEmployeeId;
    if (pDepartmentId) params.departmentId = pDepartmentId;
    if (pGuestName) params.name = pGuestName;
    if (pTypeId) params.type = pTypeId;
    if (pDateFrom) params.from = pDateFrom;
    if (pDateTo) params.to = pDateTo;
    return this._http.get<StarsReportElement[]>(environment.serverUrlAdmin + EndpointRoutes.GET_STAR_REPORT, { headers: HttpUtils.createHeaders(), params}).pipe(
      map(responseData => {
        //console.log('responseData:', responseData);
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }


}
