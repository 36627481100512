import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Vote } from 'src/app/shared/models/vote.model';
import { GuestService } from '../../guest.service';
import { Employee } from 'src/app/shared/models/employee.model';

@Component({
  selector: 'app-choose-employee',
  templateUrl: './choose-employee.component.html',
  styleUrls: ['./choose-employee.component.scss']
})
export class ChooseEmployeeComponent {

  @Input() voteObject: Vote = new Vote;
  @Output() nextStepVote: EventEmitter<Vote> = new EventEmitter();
  public mySelection: string[] = [];
  public employeeDataArray: Employee[] = [];

  public gridData: GridDataResult;

  public sortingSettings = {
    multiple: true,
    allowUnsort: true,
  };

  public state: State = {
    skip: 0,
    // Initial filter descriptor
  };

  public departmentSource: Array<{ name: string; id: number }> = [];
  public departmentArray: Array<{ name: string; id: number }>;
  public selectedDepartment: any = undefined;

  public departmentFilterActive: boolean = false;
  public employeeFilterActive: boolean = false;

  constructor(private _guestService: GuestService) {
  }

  ngOnInit(): void {
    this._setGridData();
  }

  private _setGridData() {
    this.employeeDataArray = this._guestService.getEmployees();
    this.gridData = process(this.employeeDataArray, this.state);
    this.departmentSource = this._guestService.getDepartments();
    this.departmentSource.forEach((dep,index) => {
      if (this.employeeDataArray.filter(emp => emp.departmentId === dep.id).length === 0) {
        this.departmentSource.splice(index,1);
      }
    });
    this.departmentArray = this.departmentSource;
    /*
    this.employeeDataArray.forEach(emp => {
      const found = this.departmentSource.filter(dep=> dep.id === emp.id).length > 0;
      console.log('found:', found);
      if (!found) {
        this.departmentSource.push({name:emp.departmentName, id:emp.departmentId});
      }
      this.departmentArray = this.departmentSource;
    });
    */
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.employeeDataArray, this.state);

  }


  public onFilterDepartment(value: any): void {
    if (value) {
      this.departmentArray = this.departmentSource.filter(
        (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else {
      this.departmentArray = this.departmentSource;
    }
  }

  public onSelectDepartment(input: any): void {
    if (input) {
      this.departmentFilterActive = true;
      this.state.filter =
        {
          logic: "and",
          filters: [
            {
              field: "departmentId",
              operator: "eq",
              value: input.id
            }
          ],
      };
    } else {
      this.departmentFilterActive = false;
      this.state.filter = undefined;
    }
    this.state.skip = 0;
    this.gridData = process(this.employeeDataArray, this.state);
  }



  public onFilterEmployee(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;
    if (inputValue) {
      this.employeeFilterActive = true;
      this.state.filter =
        {
          logic: "and",
          filters: [
            {
              field: "firstName",
              operator: "contains",
              value: inputValue,
            },
          ],
      };
      if (this.selectedDepartment) {
        this.state.filter.filters.push(
          {
            field: "departmentId",
            operator: "eq",
            value: this.selectedDepartment.id,
          }
        );
      }
    } else {
      this.employeeFilterActive = false;
      this.state.filter = undefined;
      if (this.selectedDepartment) {
        this.state.filter =
        {
          logic: "and",
          filters: [
            {
              field: "departmentId",
              operator: "eq",
              value: this.selectedDepartment.id,
            },
          ],
        };
      }
    }
    this.state.skip = 0;
    this.gridData = process(this.employeeDataArray, this.state);
  }

  public onStateChange(state: State): void {
    this.state = state;
    this._setGridData();
  }

  public selectEmployee(item: Employee): void {
    this.voteObject.type = 'employee';
    this.voteObject.employeeId = item.id;
    this.voteObject.employeeFirstName = item.firstName;
    this.voteObject.employeeLastName = item.lastName;
    this.voteObject.departmentId = item.departmentId;
    this.voteObject.departmentName = item.departmentName;
    this.voteObject.employeeEmail = item.email;
    this.nextStepVote.emit(this.voteObject);
  }


}
