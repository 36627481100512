import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vote } from 'src/app/shared/models/vote.model';
import { GuestService } from '../../guest.service';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { GuestLoginService } from '../../login/guest-login.service';
import { DatePipe } from '@angular/common';
import { catchError, concatMap, of } from "rxjs";


@Component({
  selector: 'app-trip',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.scss']
})
export class TripComponent {
  @Input() voteObject: Vote = new Vote;
  @Output() nextStepVote: EventEmitter<Vote> = new EventEmitter();
  @Output() resetVote: EventEmitter<undefined> = new EventEmitter();

  public scrollViewCards = [];

  public xSvg: SVGIcon = xIcon;
  public commentMaxLength: number = 500;
  public commentCharachtersCount: number;
  public commentCounter: string;
  public dialogError: boolean;
  public formatOptions: NumberFormatOptions = {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol",
  };

  public tripForm: FormGroup = new FormGroup({
    comment: new FormControl(this.voteObject.comment),
    tipAmount: new FormControl(this.voteObject.tipAmount),
    emailPaymentReceipt: new FormControl(this.voteObject.emailPaymentReceipt, Validators.email)
  });

  private _color = '8E8075'; //please don't add hash #

  constructor(private _guestService: GuestService, private _loginService: GuestLoginService, private _datePipe: DatePipe) {
  };

  ngOnInit(): void {
    this.dialogError = false;
    this.voteObject.comment = this.voteObject.comment ? this.voteObject.comment : '';
    this.commentCharachtersCount = this.voteObject.comment ? this.voteObject.comment.length : 0;
    this.commentCounter = `${this.commentCharachtersCount}/${this.commentMaxLength}`;

    this._guestService.getTripImages().subscribe((res) => {
      if (!res) return;
      this.scrollViewCards = res;
    });
  }


  public clearValue() {
    this.voteObject.comment = '';
    this.tripForm.controls['comment'].setValue('');
    this.commentCharachtersCount = 0;
    this.commentCounter = `${this.commentCharachtersCount}/${this.commentMaxLength}`;
  }

  public onCommentValueChange(ev: string): void {
    this.commentCharachtersCount = ev.length;
    this.commentCounter = `${this.commentCharachtersCount}/${this.commentMaxLength}`;
  }

  public directPayment() {
    if (this.tripForm.controls['tipAmount'].value <= 0) {
      this.dialogError = true;
      return;
    }
    this.tripForm.markAllAsTouched();
    this.voteObject.tipAmount = this.tripForm.controls['tipAmount'].value;
    this.voteObject.emailPaymentReceipt = this.tripForm.controls['emailPaymentReceipt'].value;
    this.voteObject.voteAt = this._datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm');

    this._guestService.registerTripTip(this.voteObject).pipe(
      concatMap((res) => {
        this.voteObject.requestCode = res.requestCode;
        this.voteObject.id = res.id;
        return this._guestService.makePayment(this.voteObject, this._color);
      }),
      catchError((error) => {
        this.dialogError = true;
        console.log(error);
        return of(null);
      })
    ).subscribe({
      next: (res) => {
        if (!res) return;
        this.voteObject.hasTip = true;
        localStorage.setItem('orderCode', res.orderCode);
        localStorage.setItem('voteObject', JSON.stringify(this.voteObject));
        window.open(res.link, "_self");
      },
      error: (error) => {
        this.dialogError = true;
        console.log(error);
      }
    });
  }

}
