<div class="main-content">
  <div class="maintitle">Rates and Rewards List</div>
  <div class="container">
    <div class="grid-wrapper">
      <kendo-grid
          style="max-height: 700px; margin-top: 10px !important;"
          [data]="gridData"
          [pageSize]="state.take"
          [skip]="state.skip"
          [sort]="state.sort"
          [filter]="state.filter"
          [sortable]="sortingSettings"
          [pageable]="{
            info: pagingSettings.info,
            type: pagingSettings.pagerTypes,
            pageSizes: pagingSettings.pageSizes,
            previousNext: pagingSettings.previousNext,
            position: pagingSettings.position,
            responsive: pagingSettings.responsive
          }"
          [reorderable]="true"
          [resizable]="true"
          filterable="menu"
          [columnMenu]="{ filter: true }"
          (dataStateChange)="dataStateChange($event)"
        >
        <ng-template kendoGridToolbarTemplate class="grid-toolbar">
          <div style="display:flex; width:100%; overflow-x: scroll;">
            <div class="search-bar" fxLayout="row" fxLayoutAlign="none" fxLayoutGap="20">
              <div class="search-field" fxLayout="column" fxLayoutAlign="none" fxLayoutGap="2">
                <span class="k-label"><b>Employees</b></span>
                <kendo-combobox #employeeId
                  [data]="employeesArray"
                  textField="text"
                  valueField="value"
                  [filterable]="true"
                  (filterChange)="handleEmployeeFilter($event)"
                  (selectionChange)="handleEmployeeSelection($event)"
                  placeholder="All employees"
                  [style]="'width:180px'"
                ></kendo-combobox>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="none" fxLayoutGap="2">
                <span class="k-label"><b>Departments</b></span>
                <kendo-combobox #departmentId
                  [data]="departmentsArray"
                  textField="text"
                  valueField="value"
                  [filterable]="true"
                  (filterChange)="handleDepartmentFilter($event)"
                  (selectionChange)="handleDepartmentSelection($event)"
                  placeholder="All departm."
                  [style]="'width:180px'"
                ></kendo-combobox>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="none" fxLayoutGap="2">
                <span class="k-label"><b>Guest/Room</b></span>
                <kendo-textbox #guest
                  [(ngModel)]="guestName"
                  [style]="'width:180px'"
                ></kendo-textbox>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="none" fxLayoutGap="2">
                <span class="k-label"><b>Type</b></span>
                <kendo-combobox #typeId
                  [data]="typesArray"
                  textField="text"
                  valueField="value"
                  [filterable]="true"
                  (filterChange)="handleTypeFilter($event)"
                  (selectionChange)="handleTypeSelection($event)"
                  placeholder="All types"
                  [style]="'width:180px'"
                ></kendo-combobox>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="none" fxLayoutGap="2">
                <span class="k-label"><b>From</b></span>
                <kendo-datepicker
                  name="dateFrom"
                  [(value)]="dateFrom"
                  [max]="dateFromMax"
                  [placeholder]="''"
                  [style]="'width:150px'"
                  [format]="'dd-MM-yyyy'"
                ></kendo-datepicker>
              </div>
              <div class="search-field" fxLayout="column" fxLayoutAlign="none" fxLayoutGap="2">
                <span class="k-label" ><b>To</b></span>
                <kendo-datepicker
                  name="dateTo"
                  [(value)]="dateTo"
                  [max]="dateToMax"
                  [placeholder]="''"
                  [style]="'width:150px'"
                  [format]="'dd-MM-yyyy'"
                ></kendo-datepicker>
              </div>
              <div class="filters">
                <div>
                  <kendo-label
                    style="margin-right: 10px; font-size: 14px; font-weight: bold;"
                    class="k-checkbox-label"
                    for="noFilterForm"
                    text="No filter"
                  ></kendo-label>
                  <input type="radio" name ="filters" id="noFilterForm" [checked]="isNoFilter" (click)="handleNoFilter($event)"/>
                </div>
                <div>
                  <kendo-label
                    style="margin-right: 10px; font-size: 14px; font-weight: bold;"
                    class="k-checkbox-label"
                    for="cashTipForm"
                    text="Filter Cash Tips"
                  ></kendo-label>
                  <input type="radio" name ="filters" id="cashTipForm" [checked]="isCashTip" (click)="handleCashTipFilter($event)"/>
                </div>
                <div>
                  <kendo-label
                    style="margin-right: 10px; font-size: 14px; font-weight: bold;"
                    class="k-checkbox-label"
                    for="vivaForm"
                    text="Filter Viva payment"
                  ></kendo-label>
                  <input type="radio" name="filters" id="vivaForm" [checked]="isVivaPayment" (click)="handleVivaPaymentFilter($event)"/>
                </div>
              </div>
              <div class="commands-bar" fxLayout="row" fxLayoutAlign="none" fxLayoutGap="2">
                <button kendoButton themeColor="primary" icon="search" (click)="applyFilter()">Search</button>
                <button kendoGridExcelCommand
                  [svgIcon]="fileExcelIcon"
                  size="large"
                  fillMode="link"
                  >
                  Export
                </button>
              </div>
            </div>
          </div>
        </ng-template>
        <kendo-grid-column
          field="employeeFullName"
          title="employee"
          [filterable]=""
          [headerClass]="'gridcolumn header'"
          [width]="200"
          [minResizableWidth]="200"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="departmentName"
          title="department"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="182"
          [minResizableWidth]="182"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="customerLastName"
          title="guest"
          [filterable]="false"
          [hidden]="false"
          [headerClass]="'gridcolumn header'"
          [width]="140"
          [minResizableWidth]="140"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="ratingType"
          title="type"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="122"
          [minResizableWidth]="122"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="ratingDate"
          title="date"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="117"
          [minResizableWidth]="117"
        >
            <ng-template kendoGridCellTemplate let-item>
              {{ item.ratingDate | date:'dd-MM-yyyy' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="ratingTime"
          title="time"
          [filterable]="false"
          [hidden]="true"
          [headerClass]="'gridcolumn header'"
          [width]="117"
          [minResizableWidth]="117"
        >
            <ng-template kendoGridCellTemplate let-item>
              {{ item.ratingTime | date:'HH:mm' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="ratingStar"
          title="stars"
          [filterable]="false"
          [style]="{ 'text-align': 'center' }"
          [headerClass]="'gridcolumn header'"
          [width]="117"
          [minResizableWidth]="117"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="tipAmount"
          title="tips"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="117"
          [minResizableWidth]="117"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [style]="{'color': (dataItem.failedPayment == true) ? 'red' : 'initial'}">
              € {{ dataItem.tipAmount | number:'1.2-2' }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="comment"
          title="comment"
          [filterable]="false"
          [headerClass]="'gridcolumn header'"
          [width]="200"
          [minResizableWidth]="200"
        >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <ng-container *ngIf="dataItem.hasComment">
                <div class="comment-cell"
                   [class.comment-expanded]="isCommentExpanded(dataItem)"
                   (click)="toggleCommentExpansion(dataItem)"
                >{{ dataItem.comment }}
                </div>
            </ng-container>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-excel fileName="report.xlsx" [fetchData]="excelExportData">
          <kendo-excelexport-column field="employeeFullName" title="Employee">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="departmentName" title="Department">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="customerLastName" title="Guest">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="ratingType" title="Type">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="ratingDate" title="Date">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="ratingTime" title="Time">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="ratingStar" title="Star">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="tipAmount" title="Tip Amount" [cellOptions]="amountCellOptions">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="comment" title="Comment">
          </kendo-excelexport-column>
        </kendo-grid-excel>
      </kendo-grid>
      <div *ngIf="loading" class="k-i-loading" style="height: 100vh; width: 100vw;"></div>
    </div>
  </div>
</div>
