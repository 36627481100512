import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { AdminLoginService, LoginReturnError } from './admin-login.service';
import { LoadingService } from 'src/app/shared/loading-spinner/loading.service';
import { SharedService } from 'src/app/shared/shared.service';
import { Department } from 'src/app/shared/models/department.model';
import { Employee } from 'src/app/shared/models/employee.model';
import { NotificationService } from '@progress/kendo-angular-notification';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent {

  @ViewChild('loginForm')
  loginForm!: NgForm;

  @ViewChild("password")
  public passwordField!: TextBoxComponent;

  public loginFormGroup: FormGroup;
  public errorMessage!: string;
  public userNotFoundActive: boolean;
  public locale: string;

  constructor(private _formBuilder: FormBuilder, private _router: Router, private _route: ActivatedRoute,
    private _loginService: AdminLoginService, private _sharedService: SharedService, private _notificationService: NotificationService,
    private _translate: TranslateService,
    ) {
      this._sharedService.manageLocale();
      this.loginFormGroup = this._formBuilder.group({
        'username': ['', <any>Validators.required],
        'password': ['', <any>Validators.required],
      });
  }

  public ngOnInit(): void {
    this._loginService.autoLogin();
    switch (navigator.language.toLowerCase()) {
      case 'it':
        this.locale = 'it';
        break;
      case 'de':
        this.locale = 'de';
        break;
      default:
        this.locale = 'en'
        break;
    }
  }

  public onLoginFormSubmit(): void {
    this.loginFormGroup.markAllAsTouched();
    if (this.loginFormGroup.valid) {
      this._doLogin(
        (this.loginFormGroup.value.username + '').trim(),
        (this.loginFormGroup.value.password + '').trim(),
        '/admin/main',
        )
    }
  }

  private _doLogin(username: string, password: string, redirectToPage) {
  /*  console.log('username:', username);
    console.log('password:', password);*/
    this._loginService.loginPost(username, password.trim()).subscribe({
      next: () => {
        this._loginService.userData.subscribe(userData => {
          if (userData) {
            localStorage.setItem('userData', JSON.stringify(userData));
            localStorage.setItem('X-Access-Token', userData.sessionToken);
            this._sharedService.getLoggedUser(userData.id).subscribe(accountData => {
              localStorage.setItem('locale', userData.locale);
              localStorage.setItem('accountData', JSON.stringify(accountData));
              this._sharedService.setCookie('locale', userData.locale, 365);
              const menus = this._sharedService.getMenus(userData.locale);
              localStorage.setItem('menus', JSON.stringify(menus));
              this._router.navigate([redirectToPage]).then(() => {
                this._sharedService.manageLocale();
              });
            });

          }
        });
      },
      error: (errorCode:LoginReturnError) => {
      //  alert('error1');
        this._notificationService.show({
          content:  this._translate.instant('LOGIN.errors.'+errorCode.stringCode),
          animation: { type: 'slide', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'error', icon: true },
          hideAfter: 2000,
        });
        this._translate.instant('LOGIN.errors.'+errorCode.stringCode)
      }
    });
  }

  public closeDialogUserNotFound() {
    this.userNotFoundActive = false;
  }


  public ngAfterViewInit(): void {
    const passwordEl = this.passwordField.input.nativeElement;
    passwordEl.type = passwordEl.type === "password" ? "text" : "password";
  }

  public toggleVisibility(): void {
    const passwordEl = this.passwordField.input.nativeElement;
    passwordEl.type = passwordEl.type === "password" ? "text" : "password";
  }

  public openRecoveryPage(): void {
    this._router.navigate(['/admin/recover-password']);
  }

}
