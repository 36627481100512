import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, throwError } from "rxjs";
import { HttpUtils } from "src/app/shared/http-utils";
import { environment } from "src/environments/environment";

// class for returning object
export class ReportElement {
  id: number;
  ratingType:string;
  employeeId:string;
  employeeFirstName:string;
  employeeLastName:string;
  employeeFullName:string;
  departmentId:string;
  departmentName:string;
  one:number;
  two:number;
  three:number;
  four:number;
  five:number;
  countRating:number;
  tipAmount:number;
  tipAmountFormatted:string;
  average:number;
  averageForExport:number;
}

enum EndpointRoutes {
  GET_REPORT = '/report/get',
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private _http: HttpClient) { }

  public getReport(pEmployeeId: string, pDepartmentId: string, pGuestName: string, pTypeId: string, pDateFrom: string, pDateTo: string): Observable<ReportElement[]> {
    const params:any = {};
    if (pEmployeeId) params.employeeId = pEmployeeId;
    if (pDepartmentId) params.departmentId = pDepartmentId;
    if (pGuestName) params.name = pGuestName;
    if (pTypeId) params.type = pTypeId;
    if (pDateFrom) params.from = pDateFrom;
    if (pDateTo) params.to = pDateTo;
    return this._http.get<ReportElement[]>(environment.serverUrlAdmin + EndpointRoutes.GET_REPORT, { headers: HttpUtils.createHeaders(), params}).pipe(
      map(responseReportData => {
        //console.log('responseData:', responseReportData);
        return responseReportData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }
}
