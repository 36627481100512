import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, map, Observable, of, throwError} from 'rxjs';
import { HttpUtils } from 'src/app/shared/http-utils';
import { environment } from 'src/environments/environment';
import { Employee } from '../shared/models/employee.model';
import { Department } from '../shared/models/department.model';
import { Vote } from '../shared/models/vote.model';
import { TranslateService } from "@ngx-translate/core";


enum EndpointRoutes {
  GET_DEPARTMENTS = '/departments/get',
  GET_EMPLOYEES = '/employees/get',
  RATING_IS_ALLOWED = '/rating/isAllowed',
  RATING_SAVE_VOTE = '/rating/saveVote',
  CUSTOMER_MAKE_PAYMENT = '/customer/makePayment',
  VERIFY_PAYMENT = "/payment/verify",
  CASH_TIP_REGISTRATION = "/cashTip/register",
  TRIP_REGISTRATION = "/trip/register",
  TRIP_IMAGES = "/trip/images"
}

@Injectable({
  providedIn: 'root'
})
export class GuestService {

  constructor(private _http: HttpClient, private _translate: TranslateService) { }

  /*
  public getEmployees() {

    const employeeArray: Employee[] =  [];

    let myEmployee: Employee = new Employee;
    myEmployee.id = 1;
    myEmployee.name = 'Rudolf';
    myEmployee.order = 'rudolf';
    myEmployee.pictureName = 'rudolf.png';
    myEmployee.picturePath = 'https://onboard-live.s3.eu-west-1.amazonaws.com/uploads/employee/photo/dZvez8WRboldDxAQGKy4/default_6E0A8857.JPG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAV6Q2IWRLXBE3XCX5%2F20230811%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230811T071011Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=f4f03e4ce66157c2bcccf6a556c039f3bafa6cebea67095316a419fea5ca4a22';
    myEmployee.departmentId = 1;
    myEmployee.departmentName = 'Reception';
    employeeArray.push({...myEmployee});
    myEmployee.id = 2;
    myEmployee.name = 'Martin';
    myEmployee.order = 'martin';
    myEmployee.pictureName = 'martin.png';
    myEmployee.picturePath = 'https://onboard-live.s3.eu-west-1.amazonaws.com/uploads/employee/photo/dZvez8WRboldDxAQGKy4/default_6E0A8857.JPG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAV6Q2IWRLXBE3XCX5%2F20230811%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230811T071011Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=f4f03e4ce66157c2bcccf6a556c039f3bafa6cebea67095316a419fea5ca4a22';
    myEmployee.departmentId = 3;
    myEmployee.departmentName = 'Room Service';
    employeeArray.push({...myEmployee});
    myEmployee.id = 3;
    myEmployee.name = 'Aarya';
    myEmployee.order = 'aarya';
    myEmployee.pictureName = 'aarya.png';
    myEmployee.picturePath = 'https://onboard-live.s3.eu-west-1.amazonaws.com/uploads/employee/photo/dZvez8WRboldDxAQGKy4/default_6E0A8857.JPG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAV6Q2IWRLXBE3XCX5%2F20230811%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230811T071011Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=f4f03e4ce66157c2bcccf6a556c039f3bafa6cebea67095316a419fea5ca4a22';
    myEmployee.departmentId = 1;
    myEmployee.departmentName = 'Reception';
    employeeArray.push({...myEmployee});
    myEmployee.id = 4;
    myEmployee.name = 'Felix';
    myEmployee.order = 'felix';
    myEmployee.pictureName = 'felix.png';
    myEmployee.picturePath = 'https://onboard-live.s3.eu-west-1.amazonaws.com/uploads/employee/photo/dZvez8WRboldDxAQGKy4/default_6E0A8857.JPG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAV6Q2IWRLXBE3XCX5%2F20230811%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230811T071011Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=f4f03e4ce66157c2bcccf6a556c039f3bafa6cebea67095316a419fea5ca4a22';
    myEmployee.departmentId = 2;
    myEmployee.departmentName = 'Kitchen';
    employeeArray.push({...myEmployee});
    myEmployee.id = 5;
    myEmployee.name = 'Evelyn';
    myEmployee.order = 'evelyn';
    myEmployee.pictureName = 'evelyn.png';
    myEmployee.picturePath = 'https://onboard-live.s3.eu-west-1.amazonaws.com/uploads/employee/photo/dZvez8WRboldDxAQGKy4/default_6E0A8857.JPG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAV6Q2IWRLXBE3XCX5%2F20230811%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230811T071011Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=f4f03e4ce66157c2bcccf6a556c039f3bafa6cebea67095316a419fea5ca4a22';
    myEmployee.departmentId = 2;
    myEmployee.departmentName = 'Kitchen';
    employeeArray.push({...myEmployee});
    myEmployee.id = 6;
    myEmployee.name = 'Ivan';
    myEmployee.order = 'ivan';
    myEmployee.pictureName = 'ivan.png';
    myEmployee.picturePath = 'https://onboard-live.s3.eu-west-1.amazonaws.com/uploads/employee/photo/dZvez8WRboldDxAQGKy4/default_6E0A8857.JPG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAV6Q2IWRLXBE3XCX5%2F20230811%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230811T071011Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=f4f03e4ce66157c2bcccf6a556c039f3bafa6cebea67095316a419fea5ca4a22';
    myEmployee.departmentId = 1;
    myEmployee.departmentName = 'Reception';
    employeeArray.push({...myEmployee});
    myEmployee.id = 7;
    myEmployee.name = 'Mattia';
    myEmployee.order = 'mattia';
    myEmployee.pictureName = 'mattia.png';
    myEmployee.picturePath = 'https://onboard-live.s3.eu-west-1.amazonaws.com/uploads/employee/photo/dZvez8WRboldDxAQGKy4/default_6E0A8857.JPG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAV6Q2IWRLXBE3XCX5%2F20230811%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230811T071011Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=f4f03e4ce66157c2bcccf6a556c039f3bafa6cebea67095316a419fea5ca4a22';
    myEmployee.departmentId = 3;
    myEmployee.departmentName = 'Room Service';
    employeeArray.push({...myEmployee});
    myEmployee.id = 8;
    myEmployee.name = 'Hassan';
    myEmployee.order = 'hassan';
    myEmployee.pictureName = 'hassan.png';
    myEmployee.picturePath = 'https://onboard-live.s3.eu-west-1.amazonaws.com/uploads/employee/photo/dZvez8WRboldDxAQGKy4/default_6E0A8857.JPG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAV6Q2IWRLXBE3XCX5%2F20230811%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230811T071011Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=f4f03e4ce66157c2bcccf6a556c039f3bafa6cebea67095316a419fea5ca4a22';
    myEmployee.departmentId = 3;
    myEmployee.departmentName = 'Room Service';
    employeeArray.push({...myEmployee});
    myEmployee.id = 9;
    myEmployee.name = 'Max';
    myEmployee.order = 'max';
    myEmployee.pictureName = 'max.png';
    myEmployee.picturePath = 'https://onboard-live.s3.eu-west-1.amazonaws.com/uploads/employee/photo/dZvez8WRboldDxAQGKy4/default_6E0A8857.JPG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAV6Q2IWRLXBE3XCX5%2F20230811%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230811T071011Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=f4f03e4ce66157c2bcccf6a556c039f3bafa6cebea67095316a419fea5ca4a22';
    myEmployee.departmentId = 2;
    myEmployee.departmentName = 'Kitchen';
    employeeArray.push({...myEmployee});

    return employeeArray;
  }
  */
  /*
  public getDepartments() {

    const departmentArray: Department[] =  [];

    let myDepartment: Department = new Department;
    myDepartment.id = 1;
    myDepartment.name = 'Reception';
    myDepartment.order = 'reception';
    myDepartment.pictureName = 'reception.png';
    myDepartment.picturePath = '';
    departmentArray.push({...myDepartment});

    myDepartment.id = 2;
    myDepartment.name = 'Kitchen';
    myDepartment.order = 'kitchen';
    myDepartment.pictureName = 'kitchen.png';
    myDepartment.picturePath = '';
    departmentArray.push({...myDepartment});

    myDepartment.id = 3;
    myDepartment.name = 'Room service';
    myDepartment.order = 'room service';
    myDepartment.pictureName = 'room-service.png';
    myDepartment.picturePath = '';
    departmentArray.push({...myDepartment});

    return departmentArray;
  }
*/

  public getDepartmentsFromApi(): Observable<Department[]> {
      const params = {
          language: this._translate.getDefaultLang()
      };

    return this._http.get<Department[]>(environment.serverUrl + EndpointRoutes.GET_DEPARTMENTS, { headers: HttpUtils.createHeaders(), params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getEmployeesFromApi(): Observable<Employee[]> {
    const params = {
      language: this._translate.getDefaultLang()
    };

    return this._http.get<Employee[]>(environment.serverUrl + EndpointRoutes.GET_EMPLOYEES, { headers: HttpUtils.createHeaders(), params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public ratingIsAllowed(pLastName: string, pRoom: string, pEmployeeId: string): Observable<any> {
    const params = {
      lastName: pLastName,
      room: pRoom,
      employeeId: pEmployeeId
    }
    return this._http.get<any>(environment.serverUrl + EndpointRoutes.RATING_IS_ALLOWED, {headers: HttpUtils.createHeaders(), params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public saveVote(voteObject: Vote) {
    return this._http.post<any>(environment.serverUrl +  EndpointRoutes.RATING_SAVE_VOTE, { vote: voteObject }, { headers: HttpUtils.createHeaders() }).pipe(
        map(resData => {
          return resData;
        }),
        catchError(errorRes => {
          return throwError(() => errorRes);
        })
      );
  }

  public makePayment(voteObject: Vote, pColor: string) {
    const params = {
      language: this._translate.getDefaultLang()
    };
    return this._http.post<any>(environment.serverUrl +  EndpointRoutes.CUSTOMER_MAKE_PAYMENT, {
      id: voteObject.id,
      requestCode: voteObject.requestCode,
      tipAmount: voteObject.tipAmount,
      customerEmail: voteObject.emailPaymentReceipt,
      color: pColor,
      lastName: voteObject.lastName,
      room: voteObject.room,
    }, { headers: HttpUtils.createHeaders(), params }).pipe(
        map(resData => {
          return resData;
        }),
        catchError(errorRes => {
          return throwError(() => errorRes);
        })
      );
  }

  public registerCashTip(voteObject: Vote): Observable<any> {
    return this._http.post<any>(environment.serverUrl + EndpointRoutes.CASH_TIP_REGISTRATION, {
      vote: voteObject
    }, { headers: HttpUtils.createHeaders() }).pipe(
      map(resData => {
        return resData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public registerTripTip(voteObject: Vote): Observable<any> {
    return this._http.post<any>(environment.serverUrl + EndpointRoutes.TRIP_REGISTRATION, {
      vote: voteObject
    }, { headers: HttpUtils.createHeaders() }).pipe(
      map(resData => {
        return resData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }


  public verifyPayment(pT: string, pOd: string): Observable<any> {
    const params = {
      t: pT,
      od: pOd
    }
    return this._http.get<any>(environment.serverUrl + EndpointRoutes.VERIFY_PAYMENT, {headers: HttpUtils.createHeaders(), params }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getDepartments() {
    const str = localStorage.getItem('departments');
    const obj: Department[] = JSON.parse(str);
    return obj
  }

  public getEmployees() {
    const str = localStorage.getItem('employees');
    const obj: Employee[] = JSON.parse(str);
    return obj
  }

  public getTripImages(): Observable<any> {
    return of([
      { url: '/assets/media/images/trip/amsterdam_2023.jpg' },
      { url: '/assets/media/images/trip/parigi_2019.jpg' },
      { url: '/assets/media/images/trip/roma_2022.jpg' },
    ]);

    // reactivate this code after above images are uploaded to the server and urls saved in the database
/*    return this._http.get<any>(environment.serverUrl + EndpointRoutes.TRIP_IMAGES, {headers: HttpUtils.createHeaders()}).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );*/
  }

}
