import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vote } from "../../../shared/models/vote.model";
import { SVGIcon, xIcon } from "@progress/kendo-svg-icons";
import { NumberFormatOptions } from "@progress/kendo-angular-intl";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GuestService } from "../../guest.service";
import { GuestLoginService } from "../../login/guest-login.service";
import { DatePipe } from "@angular/common";
import { catchError, concatMap, of } from "rxjs";

@Component({
  selector: 'app-cash-tip',
  templateUrl: './cash-tip.component.html',
  styleUrls: ['./cash-tip.component.scss']
})
export class CashTipComponent {
  @Input() voteObject: Vote = new Vote();
  @Output() nextStepVote: EventEmitter<Vote> = new EventEmitter();
  @Output() resetVote: EventEmitter<undefined> = new EventEmitter();

  public scrollViewCards = [];

  public departmentName: string;
  public employeeName: string;
  public employeePicturePath: string;
  public employeePictureName: string;
  public xSvg: SVGIcon = xIcon;
  public commentMaxLength: number = 500;
  public commentCharachtersCount: number;
  public commentCounter: string;
  public dialogSuccess: boolean;
  public dialogError: boolean;
  public formatOptions: NumberFormatOptions = {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "symbol",
  };

  public cashTipForm: FormGroup = new FormGroup({
    comment: new FormControl(this.voteObject.comment),
    tipAmount: new FormControl(this.voteObject.tipAmount),
  }, {validators: [Validators.required, Validators.min(0.01)]});


  private _color = '8E8075'; //please don't add hash #

  constructor(private _guestService: GuestService, private _loginService: GuestLoginService, private _datePipe: DatePipe) {
  };

  ngOnInit(): void {
    this.dialogSuccess = false;
    this.dialogError = false;

    this._guestService.getTripImages().subscribe((res) => {
      if (!res) return;
      this.scrollViewCards = res;
    });

    if (this.voteObject.departmentId) {
      const allDepartments = this._guestService.getDepartments();
      this.departmentName = allDepartments.filter(a => a.id === this.voteObject.departmentId)[0].name;
    }
    if (this.voteObject.employeeId) {
      const allEmployee = this._guestService.getEmployees();
      this.employeeName = allEmployee.filter(a => a.id === this.voteObject.employeeId)[0].firstName;
      this.employeePicturePath = allEmployee.filter(a => a.id === this.voteObject.employeeId)[0].thumbnailUrl;
      this.employeePictureName = allEmployee.filter(a => a.id === this.voteObject.employeeId)[0].id;
    }
    this.voteObject.comment = this.voteObject.comment ? this.voteObject.comment : '';
    this.commentCharachtersCount = this.voteObject.comment ? this.voteObject.comment.length : 0;
    this.commentCounter = `${this.commentCharachtersCount}/${this.commentMaxLength}`;
  }

  public clearValue() {
    this.voteObject.comment = '';
    this.cashTipForm.controls['comment'].setValue('');
    this.commentCharachtersCount = 0;
    this.commentCounter = `${this.commentCharachtersCount}/${this.commentMaxLength}`;
  }

  public onCommentValueChange(ev: string): void {
    this.commentCharachtersCount = ev.length;
    this.commentCounter = `${this.commentCharachtersCount}/${this.commentMaxLength}`;
  }

  public submit() {
    if (this.cashTipForm.controls['tipAmount'].value <= 0) {
      this.dialogError = true;
      return;
    }

    this.cashTipForm.markAllAsTouched();
    this.voteObject.tipAmount = this.cashTipForm.controls['tipAmount'].value;
    this.voteObject.voteAt = this._datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
    this.voteObject.comment = this.cashTipForm.controls['comment'].value;

    this._guestService.registerCashTip(this.voteObject).subscribe({
        next: (res) => {
          this.voteObject.requestCode = res.requestCode;
          this.voteObject.id = res.id;
          this.dialogSuccess = true;
        }, error: (error) => {
          this.dialogError = true;
          console.log(error);
        }
      }
    );
  }

  public proceedToPayment() {
    this._guestService.makePayment(this.voteObject, this._color).subscribe(res => {
      if (!res) return;
      localStorage.setItem('orderCode', res.orderCode);
      localStorage.setItem('voteObject', JSON.stringify(this.voteObject));
      window.open(res.link, "_self");
    });
  }

  public directPayment() {
    if (this.cashTipForm.controls['tipAmount'].value <= 0) {
      this.dialogError = true;
      return;
    }

    this.cashTipForm.markAllAsTouched();
    this.voteObject.tipAmount = this.cashTipForm.controls['tipAmount'].value;
    this.voteObject.voteAt = this._datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm');

    this._guestService.registerCashTip(this.voteObject).pipe(
      concatMap((res) => {
        this.voteObject.requestCode = res.requestCode;
        this.voteObject.id = res.id;
        return this._guestService.makePayment(this.voteObject, this._color);
      }),
      catchError((error) => {
        this.dialogError = true;
        console.log(error);
        return of(null);
      })
    ).subscribe({
      next: (res) => {
        if (!res) return;
        this.voteObject.hasTip = true;
        localStorage.setItem('orderCode', res.orderCode);
        localStorage.setItem('voteObject', JSON.stringify(this.voteObject));
        window.open(res.link, "_self");
      },
      error: (error) => {
        this.dialogError = true;
        console.log(error);
      }
    });
  }

  public closeDialog() {
    this.dialogSuccess = false;
    this.dialogError = false;
  }

  public registerAgain() {
    this.resetVote.emit();
  }

  public closeApplication() {
    this._loginService.logout();
  }

}
