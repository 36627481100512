<mat-toolbar color="primary" class="app-toolbar mat-elevation-z6 no-print">
  <div style="padding-top:5px; padding-right: 15px;">
    <img src="../../../../assets/media/images/logo_header.png" title="Cavallino Bianco - Tips & Rate - Admin Area" alt="Cavallino Bianco - Tips & Rate - Admin Area" style="width:120px; height:auto;"/>
  </div>
  <button mat-icon-button (click)="onToggleSidenav()">
    <mat-icon>{{menuIconName}}</mat-icon>
  </button>
  <span class="spacer"></span>
  <div fxFlex fxLayout fxLayoutAlign="flex-end">
    <ul fxLayout fxLayoutGap="10px" style="list-style: none;">
      <li class="account_info">
        <span>{{'Welcome' | translate}} {{currentUser.firstName}} {{currentUser.lastName}} </span>
      </li>
      <li>
        <button mat-icon-button (click)="doUserLogout()">
          <mat-icon>logout</mat-icon>
        </button>
      </li>
    </ul>
  </div>
</mat-toolbar>

