<div class="main-container">
  <app-main-header
    (resetVote)="resetVoteHandler()"
    [currentStep]="currentStep"
    (goToPrevious)="goToPreviousStep()"
  ></app-main-header>
  <div class="content">
    <div class="stepper-wrapper" fxLayout="row" fxLayoutAlign="center center">
      <kendo-stepper
        [style.width.%]="100"
        stepType="full"
        [steps]="steps"
        [linear]="false"
        [(currentStep)]="currentStep"
      >
        <ng-template kendoStepperLabelTemplate let-step>
          {{ step.label | translate }}
        </ng-template>
      </kendo-stepper>
    </div>
    <div class="content-wrapper" fxLayout="row" fxLayoutAlign="center center">
      <app-start
        *ngIf="currentStep === 0"
        [voteObject]="voteObject"
        (nextStepVote)="nextStepVoteHandler($event)"
        [cashTipRegistration]="cashTipRegistration"
        style="width:100%"
        ></app-start>
      <app-choose-employee
        *ngIf="currentStep === 1 && voteObject.type === 'employee'"
        [voteObject]="voteObject"
        (nextStepVote)="nextStepVoteHandler($event)"
        style="width:100%"
      ></app-choose-employee>
      <app-choose-department
        *ngIf="currentStep === 1 && voteObject.type === 'department'"
        [voteObject]="voteObject"
        (nextStepVote)="nextStepVoteHandler($event)"
        style="width:100%"
      ></app-choose-department>
      <app-trip
        *ngIf="currentStep === 2 && voteObject.type === 'trip' && !cashTipRegistration"
        [voteObject]="voteObject"
        (nextStepVote)="nextStepVoteHandler($event)"
        (resetVote)="resetVoteHandler()"
      ></app-trip>
      <app-cash-tip
        *ngIf="currentStep === 2 && cashTipRegistration"
        [voteObject]="voteObject"
        (nextStepVote)="nextStepVoteHandler($event)"
        (resetVote)="resetVoteHandler()"
      ></app-cash-tip>
      <app-vote-tip
        *ngIf="currentStep === 2 && voteObject.type !== 'trip' && !cashTipRegistration"
        [voteObject]="voteObject"
        (nextStepVote)="nextStepVoteHandler($event)"
        (resetVote)="resetVoteHandler()"
      ></app-vote-tip>
    </div>
  </div>
  <app-main-footer></app-main-footer>
</div>
