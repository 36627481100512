import { RouterModule, Routes } from '@angular/router';
import { AdminLoginComponent } from './login/admin-login.component';
import { AdminComponent } from './admin.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgModule } from '@angular/core';
import { AdminMainComponent } from './main/main.component';
import { StarsReportComponent } from './modules/stars-report/stars-report.component';
import { ReportComponent } from './modules/report/report.component';
import { VotesAndTipsListComponent } from './modules/votes-and-tips-list/votes-and-tips-list.component';

export const ADMIN_ROUTES: Routes = [
  {
    path: 'admin', component: AdminComponent, children: [
        { path: 'login', component: AdminLoginComponent},
        { path: 'recover-password', component: RecoverPasswordComponent },
        { path: 'reset-password/:recoveryCode', component: ResetPasswordComponent },
        { path: 'main', component: AdminMainComponent, children: [
          { path: 'votes-and-tips-list', component: VotesAndTipsListComponent},
          { path: 'report', component: ReportComponent},
          { path: 'star-report', component: StarsReportComponent},
        ]},

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(ADMIN_ROUTES)],
  exports: [RouterModule]
})

export class AdminRoutesModule { }

