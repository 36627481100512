import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Vote } from 'src/app/shared/models/vote.model';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent {

  @Input() cashTipRegistration: boolean;
  @Input() voteObject: Vote = new Vote;
  @Output() nextStepVote: EventEmitter<Vote> = new EventEmitter();

  public form = new FormGroup({
    type: new FormControl(null, [Validators.required]),
  });

  constructor() {}

  public ngOnInit(): void {
    this.form.controls['type'].setValue(this.voteObject.type);
  }

  public submitForm(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      if (this.form.value.type) {
        this.voteObject.type = this.form.value.type;
        this.nextStepVote.emit(this.voteObject);
      }
    }
  }
}
