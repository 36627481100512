<div class="container">
  <div class="title">{{ 'cash_tip_title' | translate }}</div>
  <div class="content">
    <form [formGroup]="cashTipForm">
      <ng-container *ngIf="voteObject.type === 'company'">
        <div class="row">
          <div class="label col-5">{{ 'hotel' | translate }}</div>
          <div class="input col-7">Cavallino Bianco</div>
        </div>
      </ng-container>
      <ng-container *ngIf="voteObject.type === 'department' || voteObject.type === 'employee'">
        <div class="row">
          <div class="label col-5">{{ 'department' | translate }}</div>
          <div class="input col-7">{{ departmentName }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="voteObject.type === 'employee'">
        <div class="row">
          <div class="label col-5">{{ 'employee' | translate }}</div>
          <div class="input col-7">{{ employeeName | titlecase }}<br><br>
            <img *ngIf="employeePicturePath; else noImage" src="{{ employeePicturePath }}"
                 alt="{{ employeePictureName }}" title="{{ employeePictureName }}" style="border-radius: 5px;"/>
            <ng-template #noImage>
              <img [width]="80" height="80px" src="assets/media/images/user_placeholder.png" title="no-picture"
                   style="border-radius: 5px">
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="voteObject.type === 'trip'">
        <app-scrollview-card
          [scrollViewItems]="scrollViewCards"
          [ngStyle]="{ width: '100%', maxWidth: 'none' }"
        ></app-scrollview-card>
        <div class="row k-text-center">{{ 'trip' | translate }}</div>
      </ng-container>

      <div class="row">
        <div class="label col-5">{{ 'reward' | translate }}</div>
        <div class="input col-7">
          <kendo-formfield>
            <kendo-numerictextbox
              #tipAmount
              format="c0"
              [min]="0.01"
              formControlName="tipAmount"
              [format]="formatOptions"
            >
            </kendo-numerictextbox>
            <kendo-formerror>{{ 'amount_error' | translate }}</kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
      <div class="row">
        <div class="label col-5">{{ 'comment' | translate }}</div>
        <div class="input col-7">
          <kendo-formfield>
            <kendo-textarea
              #comment
              [style.width.perc]="100"
              formControlName="comment"
              [rows]="5"
              flow="horizontal"
              placeholder="{{ 'comment_placeholder' | translate }}"
              resizable="none"
              [maxlength]="commentMaxLength"
              (valueChange)="onCommentValueChange($event)"
            >
            </kendo-textarea>
            <div>
              <kendo-textarea-suffix class="custom-styling">
                <span class="counter">{{ commentCounter }}</span>
                <button
                  kendoButton
                  class="suffix-clear-button"
                  fillMode="clear"
                  [svgIcon]="xSvg"
                  (click)="clearValue()"
                ></button>
              </kendo-textarea-suffix>
            </div>
          </kendo-formfield>
        </div>
      </div>
      <div class="button-row">
        <button kendoButton class="button-primary" (click)="submit()">
          {{ 'proceed' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<!-- Dialog success -->
<kendo-dialog
  class="dialog-rt dialog-star-not-choosen"
  *ngIf="dialogSuccess"
  (close)="registerAgain()"
  [minWidth]="300"
  [width]="300"
>
  <kendo-dialog-titlebar>{{ 'cash_tip_success_title' | translate }}</kendo-dialog-titlebar>
  <p style="margin: 30px; text-align: center;">
    {{ 'cash_tip_success' | translate }}
  </p>
  <kendo-dialog-actions>
<!--    <button kendoButton class="payment-button" themeColor="primary" (click)="proceedToPayment()">
      {{ 'go_to_payment' | translate | uppercase }}
    </button>-->
    <button kendoButton class="confirm-button" themeColor="primary" (click)="registerAgain()">
      {{ 'cash_tip_register_again' | translate | uppercase }}
    </button>
    <button kendoButton class="close-button" themeColor="primary" (click)="closeApplication()">
      {{ 'close_application' | translate | uppercase }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<!-- Dialog failure -->
<kendo-dialog
  class="dialog-rt dialog-star-not-choosen"
  *ngIf="dialogError"
  (close)="closeDialog()"
  [minWidth]="300"
  [width]="300"
>
  <kendo-dialog-titlebar>{{ 'cash_tip_error_title' | translate }}</kendo-dialog-titlebar>
  <p style="margin: 30px; text-align: center;">
    {{ 'cash_tip_error' | translate }}
  </p>
  <kendo-dialog-actions>
    <button kendoButton class="close-button" themeColor="primary" (click)="closeDialog()">
      {{ 'try_again' | translate | uppercase }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
