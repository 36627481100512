import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GuestService } from 'src/app/guest/guest.service';
import { GuestLoginService } from 'src/app/guest/login/guest-login.service';
import { Vote } from 'src/app/shared/models/vote.model';

@Component({
  selector: 'app-vw-result-error',
  templateUrl: './vw-result-error.component.html',
  styleUrls: ['./vw-result-error.component.scss']
})
export class VwResultErrorComponent {

  private _color = '8E8075'; //please don't add hash #
  public voteObject: Vote;

  constructor(private _router: Router, private _loginService: GuestLoginService, private _guestService: GuestService,
    ) {}

  ngOnInit(): void {
    if (!localStorage.getItem('isLogged')) {
      this._loginService.logout();
    }
    this.voteObject = JSON.parse(localStorage.getItem('voteObject'));
  }


  goHomeHandler() {
    this._router.navigate(['guest/main']);
  }

  public doAction(action: string) {
    switch(action) {
      case 'closePage':
        this._loginService.logout();
        break;
      case 'procedeToPayment':
        //alert('WORK IN PROGRESS!! Process the payment!');
        //make payment
        this._guestService.makePayment(this.voteObject, this._color).subscribe(res => {
          if (res) {
            localStorage.setItem('orderCode', res.orderCode);
            window.open(res.link, "_self");
          }
        });
        //this._loginService.logout();
        break;
    }
  }
}
