import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GuestService } from '../guest.service';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent implements OnInit{
  constructor(private _router: Router, private _guestService: GuestService) { }
  ngOnInit() {}
  public start() {
    this._router.navigate(['guest/enter']);
  }

}
