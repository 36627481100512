import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { TypographyModule } from '@progress/kendo-angular-typography';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { PopupModule } from '@progress/kendo-angular-popup';
import { GUEST_ROUTES } from './guest.routes';
import { RouterModule } from '@angular/router';
import { GuestComponent } from './guest.component';
import { JWT_OPTIONS, JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { KendoMessagesService } from '../shared/kendo-messages.service';
import { MessageService } from '@progress/kendo-angular-l10n';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LOCAL_STORAGE } from '../shared/constants';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GuestLoginComponent } from './login/guest-login.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { MainComponent } from './main/main.component';
import { MainHeaderComponent } from './main/main-header/main-header.component';
import { MainFooterComponent } from './main/main-footer/main-footer.component';
import { StartComponent } from './main/start/start.component';
import { VoteTipComponent } from './main/vote-tip/vote-tip.component';
import { ChooseEmployeeComponent } from './main/choose-employee/choose-employee.component';
import { ChooseDepartmentComponent } from './main/choose-department/choose-department.component';
import { VwPaymentComponent } from './main/vw-payment/vw-payment.component';
import { VwResultOkComponent } from './main/vw-payment/vw-result-ok/vw-result-ok.component';
import { VwResultErrorComponent } from './main/vw-payment/vw-result-error/vw-result-error.component';
import { CashTipComponent } from './main/cash-tip/cash-tip.component';
import { TripComponent } from './main/trip/trip.component';
import { ScrollviewCardComponent } from '../shared/scrollview-card/scrollview-card.component';
//import { ScrollViewModule } from "@progress/kendo-angular-scrollview";

export function tokenGetter() {
  return localStorage.getItem(LOCAL_STORAGE.CAVALLINO_TOKEN);
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    RouterModule.forChild(GUEST_ROUTES),
    BrowserModule,
    HttpClientModule,
    GridModule,
    BrowserAnimationsModule,
    FormsModule,
 //   ScrollViewModule,
    ReactiveFormsModule,
    IndicatorsModule,
    TypographyModule,
    InputsModule,
    LabelModule,
    FlexLayoutModule,
    ButtonsModule,
    DialogsModule,
    NotificationModule,
    LayoutModule,
    DropDownsModule,
    TooltipsModule,
    PopupModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    GuestLoginComponent,
    GuestComponent,
    IntroductionComponent,
    MainComponent,
    MainHeaderComponent,
    MainFooterComponent,
    StartComponent,
    VoteTipComponent,
    ChooseEmployeeComponent,
    ChooseDepartmentComponent,
    VwPaymentComponent,
    VwResultOkComponent,
    VwResultErrorComponent,
    CashTipComponent,
    TripComponent,
    ScrollviewCardComponent
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: MessageService, useClass: KendoMessagesService },
    { provide: LOCALE_ID, useValue: 'en-US' }
]
})
export class GuestModule { }
