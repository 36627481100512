import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuestComponent } from './guest/guest.component';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
  { path: 'guest', component: GuestComponent,  pathMatch: 'full' },
  { path: 'admin', component: AdminComponent,  pathMatch: 'full' },
  { path: '', redirectTo: '/guest', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }
