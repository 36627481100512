import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cavallino-tips-rating-frontend';

  constructor( public translate: TranslateService ) {
    const defaultLang = 'en';
    const langs = [defaultLang, 'it', 'de'];

    const browserLang = translate.getBrowserLang();
    translate.addLangs(langs)
    translate.setDefaultLang(langs.includes(browserLang) ? browserLang : defaultLang);
  }

  ngOnInit(): void {
  }
}
