import { Routes } from '@angular/router';
import { GuestComponent } from './guest.component';
import { GuestLoginComponent } from './login/guest-login.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { MainComponent } from './main/main.component';
import { VwPaymentComponent } from './main/vw-payment/vw-payment.component';
import { VwResultOkComponent } from './main/vw-payment/vw-result-ok/vw-result-ok.component';
import { VwResultErrorComponent } from './main/vw-payment/vw-result-error/vw-result-error.component';

export const GUEST_ROUTES: Routes = [
  { path: 'guest', component: GuestComponent },
  { path: 'guest/introduction', component: IntroductionComponent },
  { path: 'guest/enter', component: GuestLoginComponent },
  { path: 'guest/main', component: MainComponent},
  { path: 'guest/vw-payment', component: VwPaymentComponent},
  { path: 'guest/vw-result-ok', component: VwResultOkComponent},
  { path: 'guest/vw-result-error', component: VwResultErrorComponent},
];
