<div class="main-container">
  <div class="login-container">
    <div class="left-column">
      &nbsp;
    </div>
    <div class="logo">
      <img src="../../assets/media/images/logo_login.png" title="Cavallino Bianco" alt="Cavallino Bianco">
    </div>
    <div class="main-title">
      Rate & Reward<br />Admin Area
    </div>
    <div class="login-box">
      <div class="login-inner">
        <div class="login-title"><span class="icon k-icon k-i-login"></span>Admin Area Recover Password</div>
        <div class="form-container">
          <form class="k-form"  #loginForm="ngForm" (ngSubmit)="onRecoverPasswordFormSubmit()" [formGroup]="recoverPasswordFormGroup">
            <fieldset class="k-form-fieldset">
              <div class="form-fields-container">
                <kendo-formfield>
                  <kendo-label [for]="email" text="Email"> </kendo-label>
                  <kendo-textbox
                    formControlName="email"
                    #email
                    required
                  ></kendo-textbox>
                  <kendo-formerror>Email is mandatory</kendo-formerror>
                </kendo-formfield>
              </div>
              <div class="form-buttons">
                <div class="login-button-row">
                  <button kendoButton themeColor="primary" type="submit" class="button-primary">Recover Password</button>&nbsp;&nbsp;
                  <button kendoButton themeColor="secondary" fillMode="outline" class="button-link" (click)="openLoginPage()">Return to login page</button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

