<div class="main-header row">
  <div class="logo col-4">
    <img src="../../assets/media/images/logo_login.png" title="Cavallino Bianco" alt="Cavallino Bianco">
  </div>
  <div class="main-title col-8">
    Rate & Reward
  </div>
  <div class="buttons col-8">
    <kendo-button class="button" icon="undo" themeColor="primary" size="small"  (click)="goToPreviousStep()" *ngIf="currentStep > 0"></kendo-button>
    <kendo-button class="button" icon="home" themeColor="primary" size="small"  (click)="reload()" *ngIf="!showGoHome"></kendo-button>
    <kendo-button class="button" icon="home" themeColor="primary" size="small"  (click)="home()" *ngIf="showGoHome"></kendo-button>
    <kendo-button class="button !k-mr-0" icon="logout" themeColor="primary" size="small" (click)="logout()"></kendo-button>
  </div>
  <div class="welcome-bar col-12">
    {{ 'welcome_guest_room' | translate: {lastname: lastname | titlecase, room: room} }}
  </div>
</div>
